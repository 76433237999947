import { Injectable } from '@angular/core';
import {CanLoad, Router} from '@angular/router';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoadGuard implements CanLoad {

  constructor(private authService: AuthService, private router: Router) {
  }

  canLoad() {
    if (this.authService.isAuth()) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
