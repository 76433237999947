import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth/guards/auth.guard';
import { CanLoadGuard } from './services/auth/guards/can-load.guard';
import {WorkspaceGuard} from "./services/auth/guards/workspace.guard";

const routes: Routes = [
  {
    path: 'workspace',
    canActivate: [WorkspaceGuard],
    canLoad: [CanLoadGuard],
    loadChildren: () => import('./components/workspace-page/workspace-page.module').then(m => m.WorkspacePageModule)
  },
  {
    path: 'project-edit',
    canActivate: [AuthGuard],
    canLoad: [CanLoadGuard],
    loadChildren: () => import('./components/project-edit/project-edit.module').then(m => m.ProjectEditModule)
  },
  {
    path: 'project',
    canActivateChild: [AuthGuard],
    canLoad: [CanLoadGuard],
    loadChildren: () => import('./components/main-page/main-page.module').then(m => m.MainPageModule)
  },
  {
    path: 'permissions',
    canActivate: [AuthGuard],
    canLoad: [CanLoadGuard],
    loadChildren: () => import('./components/permissions/permissions.module').then(m => m.PermissionsModule)
  },
  {
    path: 'param-config',
    canActivate: [AuthGuard],
    canLoad: [CanLoadGuard],
    loadChildren: () => import('./components/param-config/param-config.module').then(m => m.ParamConfigModule)
  },
  { path: 'login', loadChildren: () => import('./components/login-page/login-page.module').then(m => m.LoginPageModule) },
  { path: '**', loadChildren: () => import('./components/login-page/login-page.module').then(m => m.LoginPageModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
