import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {StateService} from "../../services/state/state.service";
import Utils from "../../utils/utils";
import {ENTITIES_CATEGORIES_DISPLAY_NAMES} from "./consts";
import {CrudService} from "../../services/crud/crud.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {HttpStatusCode} from "@angular/common/http";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {InputModalComponent} from "../../shared/components/input-modal/input-modal.component";
import {TabParamConfigComponent} from "./tab-param-config/tab-param-config.component";
import { MenuDataStoreService } from 'src/app/services/menuDataStore/menu-data-store.service';
import { ProjectStateStoreService } from 'src/app/services/projectStateStore/project-state-store.service';

@Component({
  selector: 'app-param-config',
  templateUrl: './param-config.component.html',
  styleUrls: ['./param-config.component.scss']
})
export class ParamConfigComponent implements OnInit {

  // @ViewChild(TabParamConfigComponent) tabParamChild:TabParamConfigComponent

  @ViewChildren(TabParamConfigComponent) tabParamChildren: QueryList<TabParamConfigComponent>;


  blockMenuConfig;
  displayNamesEntityCategories = ENTITIES_CATEGORIES_DISPLAY_NAMES;
  projectName = ''
  isConfigChanged = false;
  index = 0;
  entityCategories
  entityCategoriesKeys: any;
  isLoading: boolean = true;
  paramMenuConfigs;
  blockConfigMenu;
  paramType = 'Default';
  private confirmModal: NzModalRef;

  private inputModalComponent:  ComponentFactory<InputModalComponent>;
  private inputModalComponentRef: ComponentRef<InputModalComponent>;

  constructor(private stateService: StateService,
              private projectStateStoreService: ProjectStateStoreService,
              private menuDataStoreService: MenuDataStoreService, 
              private crudService: CrudService, 
              private nzMessageService: NzMessageService,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver) {
    this.projectName = this.projectStateStoreService.projectName;
  }

  async ngOnInit() {
    this.paramMenuConfigs = await this.crudService.getAllMenuConfig();
    this.blockConfigMenu = this.paramMenuConfigs.filter((param) => param.name == this.paramType)[0];
    // const res = await this.crudService.fetchProject('Dan Park').toPromise();
    // this.stateService.menuData$.next(res.menuData);
    // this.menuDataStoreService.nextMenuData(res.menuData);
    // this.entityCategories = res.menuData;
    this.entityCategories = this.blockConfigMenu.menuData;
    this.entityCategoriesKeys = Object.keys(this.entityCategories);
    this.isLoading = false;
    this.inputModalComponent = this.componentFactoryResolver.resolveComponentFactory(InputModalComponent);
  }

  onChangeTab(e) {
    this.isConfigChanged = false;
    this.blockMenuConfig = null;
    this.index = e;
  }

  checkIfChange(e, type) {
    if (!Object.keys(e).length) {
      return;
    }
    if (!this.blockMenuConfig && e && Object.keys(e).length > 0 && this.entityCategories[this.index] === type) {
      this.blockMenuConfig = Utils.clone(e);
    } else {
      if (JSON.stringify(e) !== JSON.stringify(this.blockMenuConfig) && this.entityCategories[this.index] === type) {
        this.isConfigChanged = true;
      } else {
        this.isConfigChanged = false;
      }
    }
  }

  updateOnSave(e, type) {
    this.blockMenuConfig = e;
    // this.entityCategories[this.index] = type;
  }

  onParamConfigChange(eventValue) {
    if (eventValue === 'add') {
      this.openNewParamDialog();
      return;
    }
    this.openYesNoDialog(eventValue);
  }

  openYesNoDialog(paramConfigProfileName) {
    this.confirmModal = this.modal.confirm({
      nzTitle: `Are you sure?`,
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOnCancel: () => {},
      nzOnOk: () => this.changeParamConfig(paramConfigProfileName)
    });
  }

  changeParamConfig(paramConfigProfileName) {
    this.paramType = paramConfigProfileName;
    this.blockConfigMenu = this.paramMenuConfigs.filter((param) => param.name == this.paramType)[0];
    // this.tabParamChild.init(this.blockConfigMenu)
    this.tabParamChildren.forEach(child => child.init(this.blockConfigMenu));
  }

  openNewParamDialog() {

    this.inputModalComponentRef = this.viewContainerRef.createComponent(this.inputModalComponent);
    const sub: Subscription = this.inputModalComponentRef.instance.response.subscribe(async response => {

      if (response) {
        // @ts-ignore
        const value = response.value
        const res = await this.crudService.setMenuConfig(value);
        if (res.status === HttpStatusCode.Conflict) {
          this.nzMessageService.error(`Param Config name already exists`);
          this.inputModalComponentRef.destroy();
          sub.unsubscribe();
          return;
        }
        this.paramMenuConfigs = await this.crudService.getAllMenuConfig();
        this.changeParamConfig(value)
      }
      this.inputModalComponentRef.destroy();
      sub.unsubscribe();
      this.paramMenuConfigs = await this.crudService.getAllMenuConfig();
    });
  }

}
