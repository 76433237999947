<nz-page-header [nzGhost]="false">
  <nz-page-header-title routerLink="/workspace">
    <div class="logo-container">
      <a routerLink="/workspace">
        <img src="../../../../../assets/main-menu/UDlogo.jpeg" alt="Logo">
      </a>
    </div>
  </nz-page-header-title>
</nz-page-header>
<nz-divider style="margin:0"></nz-divider>
<div style="margin-left: 10px">
  <div style="height: 92%; width: 100%">
    <nz-select [id]="'param_select'"
               [(ngModel)]="paramType"
               (ngModelChange)="onParamConfigChange($event)"
               style="width: 100px; padding-top: 10px">
      <nz-option *ngFor="let param of paramMenuConfigs" nzValue="{{param.name}}"
                 nzLabel="{{param.name}}"
                 [id]="param">{{param.name}}</nz-option>
      <nz-option nzValue="add" nzLabel="Add+"></nz-option>
    </nz-select>
    <nz-tabset *ngIf="!isLoading" [nzSelectedIndex]="index" (nzSelectedIndexChange)="onChangeTab($event)"
                   style="height: 100%; width: 100%">
      <nz-tab [nzTitle]="displayNamesEntityCategories[s]" *ngFor="let s of entityCategoriesKeys">
        <app-tab-param-config [paramType]="paramType"
                              [type]="s"
                              [blockConfigMenu]="blockConfigMenu"
                              [projectName]="projectName"
                              [paramMenuConfigs]="paramMenuConfigs"
                         (onChange)="checkIfChange($event, s)"
                         (saveFinished)="updateOnSave($event, s)">
        </app-tab-param-config>
      </nz-tab>
    </nz-tabset>
  </div>
</div>

