import { tileLayer } from 'leaflet';
import {ENTITY_CATEGORIES} from './enums/enums';

export const URBAN_S3_URL = 'https://storage.googleapis.com/urban_files_bucket';
export const URBAN_S3_FOLDERS = {
  POI_ICONS: 'poi-icons',
  PATTERNS: 'patterns',
  CUSTOMIZED_LAYERS: 'customized-layers',
  DASHBOARD_WIDGET: 'dashboard-widget',
  ATTACHED_FILES: 'attached-files'
};
export const DEFAULT_MAP_INFO = {
  latlng: {lng: 34.7723147, lat: 32.0643525},
  coordinates: [34.7723147, 32.0643525],
  zoom: 17
};
// export const CORS_PROXY_URL = 'https://cors-proxy-urbandash-prod.b9ad.pro-us-east-1.openshiftapps.com/';
export const CORS_PROXY_URL = 'https://proxy.urbanize.ai/';
export const MAPBOX_API_KEY = 'pk.eyJ1IjoiYXpncm9uIiwiYSI6ImNrN3VpZjc4dzEyaG0zZXFyeHNwbDc1ZzMifQ.rgrM7n7yGG00LYKvTMX95A';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBml4V10aweAWV3iY3RCNZ8vjX1opCdFh4';
export const AMCHART_LICENSE = 'CH257508891';
export const MOT_LAYERS_URL = 'http://govmap/arcgis/rest/services/moti/MapServer/export';
export const MAPI_LAYERS_URL = 'http://govmap/arcgis/rest/services/AdditionalData/MapServer/export';
export const SCOPELINE_INDEX_OLD = -1;
export const BLUELINE_LANDUSE = 'blue_line';
export const BLUELINE_MAVAT_CODE = '20010';
export const SCOPE_LINE_INDEX = 'scopeline';
export const SHADOWS_INDEX = 'shadows';
export const DEFAULT_ENTITY_CATEGORY = ENTITY_CATEGORIES.DEFAULT;
export const DEFAULT_ENTITY_LANDUSE = 'default';
// export const ENTITIES_TYPES = {
//   parcel: {
//     marketableLot: 'marketable lot',
//     building: 'building',
//     publicLot: 'public lot',
//     openArea: 'open area',
//     green: 'green',
//     rightOfRoad: 'right of road',
//     infrastructure: 'infrastructure',
//     waterBody: 'water body',
//     polygon: 'polygon'
//   },
//   network: {
//     polyline: 'polyline'
//   },
//   poi: {
//     POI: 'POI',
//     COMMENT: 'comment'
//   },
//   influence: {
//     influence: 'influence'
//   }
// };

export const ENTITIES_TYPES = {
  parcel: {
    residential: 'residential',
    retail: 'retail',
    office: 'office',
    hotel: 'hotel',
    mix_Use: 'mix_Use',
    green_area: 'green_area',
    industrial: 'industrial',
    agriculture: 'agriculture',
    other_private: 'other_private',
    public_buildings: 'public_buildings',
    park: 'park',
    road_new: 'road_new',
    road_existing: 'road_existing',
    pedestrian_path: 'pedestrian_path',
    infrastructure: 'infrastructure',
    water: 'water',
    other_public: 'other_public',
    default: 'default'
  },
  building: {
    building: 'building',
    floor: 'floor'
  },
  network: {
    road_new: 'road_new',
    road_existing: 'road_existing',
    pedestrian_path_new: 'pedestrian_path_new',
    pedestrian_path_existing: 'pedestrian_path_existing',
    bicycle_lane: 'bicycle_lane',
    train_rail: 'train_rail',
    metro_line: 'metro_line',
    default: 'default'    
  },
  poi: {
    POI: 'POI',
    COMMENT: 'comment', // drawn comment
    train_Station: 'train_Station',
    tree: 'tree',
    metro_Station: 'metro_station',
    bus_station: 'bus_station',
    light_train_station: 'light_train_station',
    amenity: 'amenity',
    comments: 'comments',
    landmark: 'landmark'
  },
  junction: {
    junction_roads: 'Junction_roads',
    junction_road_and_path: 'Junction_road_and_path',
    junction_paths: 'Junction_paths',
  },
  guideline_elements: {
    lot_line: 'lot_line',
    plan_baundary: 'plan_Baundary',
    commercial_front: 'commercial_front',
    colonade: 'colonade',
    normative_area: 'normative_area',
    measurments: 'measurments',
    default: 'default'
  },
  default: {
    default_polygon: 'default_polygon',
    default_polyline: 'default_polyline',
    default_point: 'default_point'
  }
};

// export const entityTypesToTurn = [
  // ENTITIES_TYPES.parcel.building,
  // ENTITIES_TYPES.parcel.marketableLot,
  // ENTITIES_TYPES.parcel.publicLot,
  // ENTITIES_TYPES.parcel.openArea,
  // ENTITIES_TYPES.parcel.green,
  // ENTITIES_TYPES.parcel.rightOfRoad,
  // ENTITIES_TYPES.parcel.infrastructure,
  // ENTITIES_TYPES.parcel.waterBody,
  // ENTITIES_TYPES.parcel.polygon];

//   export const entityTypesToTurn = [
//   ENTITIES_TYPES.building.residential,
//   ENTITIES_TYPES.building.retail,
//   ENTITIES_TYPES.parcel.residential,
//   ENTITIES_TYPES.parcel.office,
//   ENTITIES_TYPES.parcel.green_area,
// ];
export const ENTITY_PLAN_TYPE = ['general', 'metro', 'landscape', 'transportation', 'urban'];
export const DEFAULT_COLOR = '#adadad';
export const SELECTED_COLOR = '#4a90e2';
export const HOVERED_COLOR = '#4a90e2';
export const TEMP_NAME = 'temp_name';
export const SHORT_DURATION = 1500;
export const LONG_DURATION = 5000;
export const GEOJSON_PRECISION = 7;
export const DASHBOARD_WIDGET_TABS_DEFAULTS = [
  {
    icon: 'Stats.svg',
    url: 'https://storage.googleapis.com/urban_files_bucket/dashboard/default/Stats.svg',
    isDefault: true,
    widgets: []
  },
  {
    icon: 'Residential.svg',
    url: 'assets/right-dashboard/Residential.svg',
    isDefault: true,
    widgets: []
  },
  {
    icon: 'Cost.svg',
    url: 'https://storage.googleapis.com/urban_files_bucket/dashboard/default/Cost.svg',
    isDefault: true,
    widgets: []
  },
  {
    icon: 'PublicNeeds.svg',
    url: 'assets/right-dashboard/PublicNeeds.svg',
    isDefault: true,
    widgets: []
  },
  {
    icon: 'transportation.svg',
    url: 'https://storage.googleapis.com/urban_files_bucket/dashboard/default/transportation.svg',
    isDefault: true,
    widgets: []
  },
  {
    icon: 'env.svg',
    url: 'https://storage.googleapis.com/urban_files_bucket/dashboard/default/env.svg',
    isDefault: true,
    widgets: []
  }
];


export const MAPSTYLE = {
  Default: tileLayer('https://api.mapbox.com/styles/v1/yemimatamir/cl2zxml3o004c14qn8hkf0n73/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieWVtaW1hdGFtaXIiLCJhIjoiY2wxNTE0OWxuMHdvMjNkczBveDJqMXFhYiJ9.8gQoryAW-JiF4JsRmZiVUw', {
    id: 'ud-baselayer-default',
    maxZoom: 22,
    attribution: 'Urban-Dashboard © | Mapbox © | &copy; OpenStreetMap contributors',
  }),
  Satellite: tileLayer(`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=${MAPBOX_API_KEY}`, {
    id: 'ud-baselayer-satellite',
    maxZoom: 22,
    attribution: 'Urban-Dashboard © | Mapbox © | &copy; OpenStreetMap contributors'
  }),
  None: tileLayer('', {
    id: 'ud-baselayer-none',
    maxZoom: 22,
    attribution: 'Urban-Dashboard © | Mapbox © | &copy; OpenStreetMap contributors'
  })
};

export const FLOORRANGEDEFAULT = {
  MAINUSAGEPARCENT: 80,
  SERVICEUSAGEPARCENT: 20,
  SERVICEUSAGETYPE: 'core',
  SERVICEFORUSAGE: 'residential',
  UNITSIZE: 80,
}