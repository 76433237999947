<div class="menu-config-container">

  <div *ngIf="isLoading"
       style="margin-top: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; align-self: center">
    <nz-spin [nzSize]="'large'" *ngIf="isLoading"></nz-spin>
  </div>
  <div *ngIf="blockConfigMenu && !isLoadingBlockConfig && !isLoading"
       style="display: flex; flex-direction: row; align-items: center; justify-content: space-around; width: 30%; margin-bottom: 10px; margin-top: 15px"
  >

    <button nz-button [nz-tooltip]="'Save All'" (click)="save()">
      <i nz-icon nzType="save"></i>
    </button>
    <button nz-button [nz-tooltip]="'Add Category'"
            (click)="openDialog('New Category', 'Category name',  {type: 'category'})">
      <i nz-icon nzType="plus-square"></i>
    </button>
    <div style="width: 60%; direction: ltr; text-align: left; margin-left: 5px">
      <input nz-input style="direction: ltr !important;" autocomplete="off" [(ngModel)]="searchParam"
             type="text" [placeholder]="'Search name or code...'"/>
    </div>
    <button nz-button [nz-tooltip]="'Search'"
            (click)="getFilteredParam(this.blockConfigMenu.menuData[this.type])">
      <i nz-icon nzType="search"></i>
    </button>
    <button nz-button [nz-tooltip]="'Reset Search'"
            (click)="getFilteredParam(this.blockConfigMenu.menuData[this.type], true)">
      <i nz-icon nzType="minus-square"></i>
    </button>
  </div>

  <nz-collapse cdkDropList class="collapse" style="width: 97%; margin: 10px" *ngIf="blockConfigMenu && !isLoadingBlockConfig && !isLoading" (cdkDropListDropped)="drop($event)">

    <!--    landUse-->
    <nz-collapse-panel [nzHeader]="header"> <!-- can refactor to a component for better view -->
      <ng-template #header>
        <span class="high-opacity">{{type == 'influence' ? 'Affects types' : 'Land usages'}}</span>
      </ng-template>

      <div style="display: flex; justify-content: flex-start; flex-direction: row">
        <button nz-button [nz-tooltip]="'Add New Param'" style="margin-bottom: 10px; margin-top: -5px"
                (click)="openDialog('New Param', 'Param Name', {type: 'param', menuKey: 'landuse'})"
                >
          <i nz-icon nzType="plus-square"></i>
        </button>
      </div>

        <app-param-config-data-table (sendRefToParent)="swithIsLoading($event)"  (notifyDelete)="deleteParam($event)" [subCategory]="subCategories.landUse" [data]="landUse" (notifyUpload)="uploadFileStaging($event)"  ></app-param-config-data-table>  


      <!-- this table only corralate with landuse type of data (I refactor this to the app-param-config-data-table so i believe it can be deleted)-->
      <!-- <nz-table [nzScroll]="{ x: '1000px', y: '700px' }">
        <div nz-row [nzGutter]="[16, 10]">
        <div style="width: 25%;padding:10px;" *ngFor="let l of getObjKeys(landUse) ; let i = index;">
          <div class="inner-box param-card">
            <div style="border-bottom: 1px solid rgba(0,0,0,0.1); padding:14px">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-around; width:70%; margin:auto;">
                <input nz-input [id]="l+'-displayName'" autocomplete="off" [(ngModel)]="landUse[l].displayName">
                <button nz-button [nz-tooltip]="'Delete Parameter'" (click)="deleteParam(l)" [id]="l+'-delete-param'">
                  <i nz-icon nzType="delete"></i>
                </button>
              </div>
            </div>
            <div class="" style="padding:23px" >
              <div>
                <div style="text-align: center; display: grid; grid-template-columns: 10fr">
                  <div style="display:flex;margin-left: 10px;">
                    <div >
                      <i nz-icon nzType="bg-colors" nzTheme="outline" style="margin-left: -5px;font-size: 17px;"></i>
                    </div>
                    <div style="margin-left: 10px; margin-top: -9px" [nz-tooltip]="'Fill Color'">
                      <input
                        style="width: 60px;"
                         [id]="l+'-fill-color'"
                         [(colorPicker)]="landUse[l].color"
                         [value]="getHexColor(landUse[l].color) || ''"
                         [cpPosition]="'top'"
                         [cpOutputFormat]="'hex'"
                         [style.background]="getHexColor(landUse[l].color)"/>
                    </div>
                    <div style="margin-left: 30px;">
                      <label [nz-tooltip]="'Code'"> {{l}}</label>
                    </div>
                  </div>

                  <nz-row style="margin-top: 10px; margin-left: -10px;">
                    <nz-col nzSpan="3">
                      <i  [nz-tooltip]="'Thickness'" nz-icon nzType="highlight" nzTheme="outline" style="font-size: 17px;"></i>
                    </nz-col>
                    <nz-col nzSpan="8">
                      <div>
                        <nz-slider style="background-color: #4f4cd1 !important; width: 20px" [nzStep]="1" [nzMin]="0" [nzMax]="8" [id]="l+'-thickness-slider'"
                                    [(ngModel)]="landUse[l].thickness"></nz-slider>
                      </div>
                    </nz-col>
                    <nz-col nzSpan="3">
                      <i [nz-tooltip]="'Line Dash'" nz-icon nzType="dash" nzTheme="outline" style="margin-left: -5px;font-size: 17px;"></i>
                    </nz-col>
                    <nz-col nzSpan="8">
                        <div>
                          <nz-slider class="slider-input" [nzStep]="1" [nzMin]="0" [nzMax]="4" [id]="l+'-dash-slider'"
                                     [(ngModel)]="landUse[l].lineDash"></nz-slider>
                        </div>
                    </nz-col>
                  </nz-row>

                  <nz-row style="margin-left: -10px;">
                    <nz-col nzSpan="3">
                      <i [nz-tooltip]="'Max Zoom'" nz-icon nzType="zoom-in" nzTheme="outline" style="font-size: 17px;"></i>
                    </nz-col>
                    <nz-col nzSpan="8">
                      <div>
                        <nz-slider style="width: 100px !important;" [nzStep]="1" [nzMin]="1" [nzMax]="22"
                                    [id]="l+'-zoom-out-slider'" [(ngModel)]="landUse[l].maxZoom"></nz-slider>
                      </div>
                    </nz-col>
                    <nz-col nzSpan="3">
                      <i  [nz-tooltip]="'Min Zoom'" nz-icon nzType="zoom-out" nzTheme="outline" style="font-size: 17px;"></i>
                    </nz-col>
                    <nz-col nzSpan="8">
                      <div>
                        <nz-slider style="width: 100px !important;" [nzStep]="1" [nzMin]="1" [nzMax]="22"
                                    [id]="l+'-zoom-in-slider'" [(ngModel)]="landUse[l].minZoom"></nz-slider>
                      </div>
                      </nz-col>
                  </nz-row>

                  <nz-row style="margin-left: -10px;">
                    <nz-col nzSpan="3">
                      <i [nz-tooltip]="'Opacity'" nz-icon nzType="bulb" nzTheme="outline" style="font-size: 17px;"></i>
                    </nz-col>
                    <nz-col nzSpan="8">
                      <div>
                        <nz-slider style="width: 100px !important;" [nzStep]="0.05" [nzMin]="0.1" [nzMax]="1"
                                   [id]="l+'-opacity-slider'" [(ngModel)]="landUse[l].opacity"></nz-slider>
                      </div>
                    </nz-col>
                    <nz-col nzSpan="3">
                      <i [nz-tooltip]="'Lazy Loading'" nz-icon nzType="hourglass" nzTheme="outline" style="font-size: 17px; margin-top: 5px"></i>
                    </nz-col>
                    <nz-col nzSpan="8">
                      <div>
                        <nz-switch nzSize="small" style="width: 100px !important; margin-left: -45px"
                                   [id]="l+'-lazy-loading'" [(ngModel)]="landUse[l].lazyLoading"></nz-switch>
                      </div>
                    </nz-col>
                  </nz-row>

                  <nz-row style="margin-left: -10px;">
                    <nz-col nzSpan="6" style="margin-left: 10px">
                      <input [nzTooltipTitle]="'z-index'" [id]="l+'-zIndex'" nz-input nz-tooltip autocomplete="off" [placeholder]="'zIndex'"
                             [(ngModel)]="landUse[l].zIndex">
                    </nz-col>
                    <nz-col nzSpan="3">
                      <i [nz-tooltip]="'Stroke Color'" nz-icon nzType="border-outer" nzTheme="outline" style="font-size: 17px; margin-left: 52px; margin-top: 10px"></i>
                    </nz-col>
                    <div style="margin-left: 35px">
                      <input [(colorPicker)]="landUse[l].strokeColor" [value]="getHexColor(landUse[l].strokeColor) || ''"
                             [id]="l+'-stroke-color'"
                             [cpPosition]="'top'"
                             [cpOutputFormat]="'hex'"
                             style="width: 60px;"
                             [style.background]="getHexColor(landUse[l].strokeColor)"/>
                    </div>
                  </nz-row>

                  <nz-row style="margin-top: 10px">
                    <nz-col nzSpan="2">
                      <img src="{{getPatternUrl(l)}}" alt="" style="width: 20px; height: 20px; border-radius: 6px" *ngIf="!isLoadingUploadFile[l] && !is3D(l)"/>
                    </nz-col>
                    <nz-col nzSpan="8">
                      <div class="avatar-edit" [nz-tooltip]="'Edit Pattern'">
                        <input type="file" [id]="'upload_file' + l"
                               (change)="uploadFileStaging($event.target, l)" accept=".jpg, .png, .gltf"/>
                        <label [for]="'upload_file' + l" *ngIf="!isLoadingUploadFile[l]">
                        </label>
                        <nz-spin [nzSize]="'small'" *ngIf="isLoadingUploadFile[l]" style="margin-right: 10px">
                        </nz-spin>
                      </div>
                      <div class="avatar-preview">
                        <div *ngIf="!this.isLoadingUploadFile[l] && this.is3D(l)">
                          <app-gltf-viewer [width]="50" [height]="50" [url]="getPatternUrl(l)"
                                           style="border-radius: 100px"></app-gltf-viewer>
                        </div>
                      </div>
                    </nz-col>
                  </nz-row>
                  <nz-row style="margin-top: 10px">
                    <nz-col nzSpan="2">
                      <div style="margin-left: 5px;  width: 80px !important;" *ngIf="type == 'parcel'">
                        <input nz-input autocomplete="off" [placeholder]="'Mavat Code'"
                               [(ngModel)]="landUse[l].mavatCode">
                      </div>
                    </nz-col>
                  </nz-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </nz-table> -->
    </nz-collapse-panel>

    

<!--        filteredMenuData without landUse  (Usage)--> 
    <nz-collapse-panel [nzHeader]="header" *ngFor="let param of this.filteredMenuData | keyvalue">
      <ng-template #header>
        <span class="high-opacity">{{param.key}}</span>
      </ng-template>
      <ng-container>
        
        
        <ng-template #header>
          <span class="high-opacity">{{type == 'influence' ? 'Affects types' : 'Land usages'}}</span>
        </ng-template>
  
        <div style="display: flex; justify-content: flex-start; flex-direction: row">
          <button nz-button [nz-tooltip]="'Add New Param'" style="margin-bottom: 10px; margin-top: -5px"
                  (click)="openDialog('New Param', 'Param Name', {type: 'param', menuKey: 'usage'})"
                  >
            <i nz-icon nzType="plus-square"></i>
          </button>
        </div>
        <app-param-config-data-table (sendRefToParent)="swithIsLoading($event)" (notifyDelete)="deleteParam($event)" (notifyUpload)="uploadFileStaging($event)" [subCategory]="subCategories.usage" [data]="usage" ></app-param-config-data-table> 
        
      </ng-container>
    </nz-collapse-panel>


    
<!--        filteredMenuData without landUse (Old Component for old data type)-->
<!-- <nz-collapse-panel [nzHeader]="header" *ngFor="let param of this.filteredMenuData | keyvalue">
  <ng-template #header>
    <span class="high-opacity">{{param.key}}</span>
  </ng-template>
  <ng-container>
    <div style="display: flex; justify-content: flex-start; flex-direction: row; margin-bottom: 10px">
      <button [nz-tooltip]="'Add New Param'"
              (click)="openDialog('New Param', 'Param Name', {type: 'param', menuKey: param.key})"
              style="margin-left: 10px">
        <i nz-icon nzType="plus"></i>
      </button>

      <button [nz-tooltip]="'Delete Category'"
              (click)="deleteParam(param.key, -1, param.key)"
              style="margin-left: 10px">
        <i nz-icon nzType="delete"></i>
      </button>

      <button [nz-tooltip]="'Rename'"
              (click)="openDialog('Rename', 'Category name',  {type: 'rename', menuKey: param.key}, param.key)"
              style="margin-left: 10px">
        <i nz-icon nzType="edit"></i>
      </button>
    </div>

    <nz-table [nzScroll]="{ x: '1000px', y: '600px' }">
    <nz-row *ngFor="let p of getParamsValues(param); let i = index;">
      <nz-card class="param-card" [nzExtra]="menuDataCardHeader" style="width: 600px">
        <div>
          <nz-select [(ngModel)]="p.widget" required >
            <nz-option *ngFor="let v of options" [nzLabel]="v" [nzValue]="v">{{v}}</nz-option>
          </nz-select>

          <nz-form-control *ngIf="p.widget == 'DROPDOWN' || p.widget == 'MULTISELECT'">
                <textarea nz-input nz-tooltip [nzTooltipTitle]="'Values'" [placeholder]="'Values'" [(ngModel)]="p.values" cdkTextareaAutosize
                          cdkAutosizeMinRows="6"></textarea>
          </nz-form-control>
          <nz-form-control *ngIf="p.widget == 'SLIDER' || p.widget == 'INPUT_NUMBER'">
            <input nz-input autocomplete="off" nz-tooltip [nzTooltipTitle]="'Max'" [placeholder]="'Max'" [(ngModel)]="p.max">
          </nz-form-control>
          <nz-form-control *ngIf="p.widget == 'SLIDER' || p.widget == 'INPUT_NUMBER'">
            <input nz-input autocomplete="off" nz-tooltip [nzTooltipTitle]="'Min'" [placeholder]="'Min'" [(ngModel)]="p.min">
          </nz-form-control>
          <nz-form-control *ngIf="p.widget == 'SLIDER' || p.widget == 'INPUT_NUMBER'">
            <input nz-input autocomplete="off" nz-tooltip [nzTooltipTitle]="'Increment'" [placeholder]="'Increment'" [(ngModel)]="p.increment">
          </nz-form-control>
          <nz-form-control *ngIf="p.widget == 'SLIDER' || p.widget == 'INPUT_NUMBER'">
            <input nz-input autocomplete="off" nz-tooltip [nzTooltipTitle]="'Type'" [placeholder]="'Type'" [(ngModel)]="p.valueType">
          </nz-form-control>
          <nz-form-control>
            <input nz-input autocomplete="off" nz-tooltip [nzTooltipTitle]="'Code'" [placeholder]="'Code'" [(ngModel)]="p.code">
          </nz-form-control>
          <nz-form-control>
            <input nz-input autocomplete="off" nz-tooltip [nzTooltipTitle]="'Default Value'" [placeholder]="'Default Value'"
                   [(ngModel)]="p.defaultValue">
          </nz-form-control>
          <app-drop-down-multiple style="margin-left: -100px !important;" (onChange)="onChangeLandUses($event, param.key, i)" [options]="getLandUses()"
                                  [value]="p.landUses">
          </app-drop-down-multiple>
        </div>
      </nz-card>

      <ng-template #menuDataCardHeader>
        <div style="direction: ltr;">
          <input nz-tooltip [nzTooltipTitle]="'Name'" style="width: 512px" nz-input autocomplete="off" [placeholder]="'Name'" [(ngModel)]="p.inputParameter">
          <button nz-button [nz-tooltip]="'Delete Parameter'" (click)="deleteParam(param.key, i, p.inputParameter)">
            <i nz-icon nzType="delete"></i>
          </button>
        </div>
      </ng-template>

    </nz-row>
    </nz-table>
  </ng-container>
</nz-collapse-panel> -->
  </nz-collapse>
</div>
