import { Injectable } from '@angular/core';
import { Feature, Geometry } from 'geojson';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { take, catchError, map, switchMap, mergeMap, shareReplay, concatMap, tap } from 'rxjs/operators';
import { PlanInterface } from 'src/app/models/plan-interface';
import Utils from 'src/app/utils/utils';
import { CrudService } from '../crud/crud.service';
import { EntityProperties } from 'src/app/models/entity-properties';
import { ProjectStateStoreService } from '../projectStateStore/project-state-store.service';

@Injectable({
  providedIn: 'root'
})
export class PlansStoreService {

  private plansSubject: BehaviorSubject<PlanInterface[]> = new BehaviorSubject<any>([]);
  plans$: Observable<PlanInterface[]> = this.plansSubject.asObservable();

  constructor(private crud: CrudService, private projectStateStoreService: ProjectStateStoreService) { }

  nextPlans(plans: PlanInterface[]) {
    this.plansSubject.next(plans);
  }

  plansValue(): PlanInterface[] {
    return this.plansSubject.getValue()
  }

  getPlanByName(planName: string): Observable<PlanInterface | null> {
    return this.plans$.pipe(
      map(plans => plans.find(plan => plan.planName === planName) || null)
    );
  }
  

  addPlan(plan: PlanInterface): Observable<any> {
    // Add the plan to the local plans array
    const currentPlans = Utils.clone(this.plansSubject.getValue());
    this.plansSubject.next([...currentPlans, plan]);

    // Update the plan in the backend using CrudService.upsertPlanObservable()
    const upsertPlanObs = this.crud.upsertPlanObservable(plan);
    return upsertPlanObs.pipe(
      take(1),
      catchError(error => {
        // If there was an error updating the plan in the backend, remove the plan from the local plans array
        // const updatedPlans = this.plansSubject.getValue().filter(p => p !== plan);
        this.plansSubject.next(currentPlans);
        return throwError(error)
      })
    )
  }

  updatePlan(plan: PlanInterface): Observable<any> {
    // Find the index of the plan to be updated in the local plans array
    const currentPlans = Utils.clone(this.plansSubject.getValue());
    const index = currentPlans.findIndex(p => p.planName === plan.planName);
    if (index < 0) {
      return throwError(`Plan ${plan.planName} not found`);
    }

    // Update the plan in the local plans array
    currentPlans[index] = plan;
    this.plansSubject.next(currentPlans);

    // Update the plan in the backend using CrudService.upsertPlanObservable()
    const upsertPlanObs = this.crud.upsertPlanObservable(plan);
    return upsertPlanObs.pipe(
      catchError(error => {
        // If there was an error updating the plan in the backend, revert the changes made to the local plans array
        this.plansSubject.next(Utils.clone(this.plansSubject.getValue()));
        return throwError(error)
      })
    );
  }

  // Working  original method
  // removePlan(plan: PlanInterface): Observable<any> {
  //   // Remove the plan from the local plans array
  //   const currentPlans = Utils.clone(this.plansSubject.getValue());
  //   const updatedPlans = currentPlans.filter(p => p !== plan);
  //   this.plansSubject.next(updatedPlans);

  //   // Remove the plan from the backend using CrudService.removePlanObservable()
  //   const removePlanObs = this.crud.deletePlanObservable(plan.projectName, plan.planName);
  //   return removePlanObs.pipe(
  //     take(1),
  //     catchError(error => {
  //       // If there was an error removing the plan from the backend, add the plan back to the local plans array
  //       this.plansSubject.next(currentPlans);
  //       return throwError(error)
  //     })
  //   )
  // }

  // with changing the currentplan
  removePlan(plan: PlanInterface): Observable<any> {
    // Remove the plan from the local plans array
    const currentPlans = Utils.clone(this.plansSubject.getValue());
    const updatedPlans = currentPlans.filter(p => p.planName !== plan.planName);
    this.plansSubject.next(updatedPlans);
  
    // Remove the plan from the backend using CrudService.removePlanObservable()
    const removePlanObs = this.crud.deletePlanObservable(plan.projectName, plan.planName);
    return removePlanObs.pipe(
      take(1),
      concatMap(() => {
        // Get the updated list of plans
        return this.plans$.pipe(
          take(1),
          concatMap((plans: PlanInterface[]) => {
            // Check if the current plan was deleted
            return this.projectStateStoreService.currentPlan$.pipe(
              take(1),
              tap(currentPlan => {
                if (currentPlan.planName === plan.planName) {
                  // Change the current plan to the first plan in the plans list
                  const firstPlan = plans.find(p => p.planName !== plan.planName);
                  if (firstPlan) {
                    this.projectStateStoreService.nextSelectedEntities([])
                    this.projectStateStoreService.nextCurrentPlan(firstPlan);
                  }
                }
              })
            );
          })
        );
      }),
      catchError(error => {
        // If there was an error removing the plan from the backend, add the plan back to the local plans array
        this.plansSubject.next(currentPlans);
        return throwError(error);
      })
    );
  }
  

  addEntityToPlanConfig(entity: Feature<Geometry, EntityProperties>, planName: string, parentId?: string): Observable<any> {
    return this.plans$.pipe(
      take(1),
      map(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        if (targetPlan) {
          if (!targetPlan.planConfig[entity.id]) {
            targetPlan.planConfig[entity.id] = { version: 'v0' };
          }
          if (parentId) {
            targetPlan.planConfig[entity.id].parentId = parentId;
          }
          this.plansSubject.next(Utils.clone(plans));
          console.log("After Next Plan ", Utils.clone(plans))
          return targetPlan;
        }
        throw new Error(`Plan ${planName} not found`);
      }),
      switchMap(targetPlan => {
        console.log("Plan to be upsert: ", targetPlan)
        const upsertPlanObs = this.crud.upsertPlanObservable(targetPlan);
        return upsertPlanObs.pipe(
          catchError(error => {
            // If there was an error updating the plan in the backend, remove the entity from the plan config
            delete targetPlan.planConfig[entity.id];
            this.plansSubject.next(Utils.clone(this.plansSubject.getValue()));
            return throwError(error);
          })
        );
      })
    );
  }

  // add Entity to planConfig without saving 
  addEntityToPlanConfig2(entity: Feature<Geometry, EntityProperties>, planName: string, parentId?: string): Observable<any> {
    return this.plans$.pipe(
      take(1),
      map(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        if (targetPlan) {
          if (!targetPlan.planConfig[entity.id]) {
            targetPlan.planConfig[entity.id] = { version: 'v0' };
          }
          if (parentId) {
            targetPlan.planConfig[entity.id].parentId = parentId;
          }
          this.plansSubject.next(Utils.clone(plans));
          console.log("After Next Plan ", Utils.clone(plans))
          return targetPlan;
        }
        throw new Error(`Plan ${planName} not found`);
      }),
    );
  }

    // Add this method to the PlansStoreService class
  addEntitiesToPlanConfig(entities: Feature<Geometry, EntityProperties>[], planName: string): Observable<any> {
    return this.plans$.pipe(
      take(1),
      map(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        if (targetPlan) {
          entities.forEach(entity => {
            if (!targetPlan.planConfig[entity.id]) {
              targetPlan.planConfig[entity.id] = { version: 'v0' };
            }
          });
          this.plansSubject.next(Utils.clone(plans));
          return targetPlan;
        }
        throw new Error(`Plan ${planName} not found`);
      }),
      switchMap(targetPlan => {
        const upsertPlanObs = this.crud.upsertPlanObservable(targetPlan);
        return upsertPlanObs.pipe(
          catchError(error => {
            // If there was an error updating the plan in the backend, revert the changes made to the plan config
            entities.forEach(entity => {
              delete targetPlan.planConfig[entity.id];
            });
            this.plansSubject.next(Utils.clone(this.plansSubject.getValue()));
            return throwError(error);
          })
        );
      })
    );
  }

  
  removeEntityFromPlanConfig(entity: Feature<Geometry, EntityProperties>, planName: string): Observable<any> {
    return this.plans$.pipe(
      take(1),
      switchMap(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        if (targetPlan && targetPlan.planConfig[entity.id]) {
          delete targetPlan.planConfig[entity.id];
          this.plansSubject.next(Utils.clone(plans));
          // Update the plan in the backend using CrudService.upsertPlanObservable()
          const upsertPlanObs = this.crud.upsertPlanObservable(targetPlan);
          return upsertPlanObs.pipe(
            catchError(error => {
              // If there was an error updating the plan in the backend, add the entity back to the plan config
              targetPlan.planConfig[entity.id] = { version: 'v0' };
              this.plansSubject.next(Utils.clone(plans));
              return throwError(error)
            })
          );
        }
        return of(null);
      })
    );
  }


  removeEntitiesFromPlanConfig(entities: Feature<Geometry, EntityProperties>[], planName: string): Observable<any> {
    return this.plans$.pipe(
      take(1),
      concatMap(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        // console.log("TP in func", targetPlan)
        if (targetPlan) {
          entities.forEach(entity => {
            if (targetPlan.planConfig[entity.id]) {
              delete targetPlan.planConfig[entity.id];
            }
          });
          this.plansSubject.next(Utils.clone(plans));
          // Update the plan in the backend using CrudService.upsertPlanObservable()
          const upsertPlanObs = this.crud.upsertPlanObservable(targetPlan);
          return upsertPlanObs.pipe(
            shareReplay(),
            catchError(error => {
              // If there was an error updating the plan in the backend, add the entities back to the plan config
              entities.forEach(entity => {
                if (!targetPlan.planConfig[entity.id]) {
                  targetPlan.planConfig[entity.id] = { version: 'v0' };
                }
              });
              this.plansSubject.next(Utils.clone(plans));
              return throwError(error);
            })
          );
        }
        return of(null);
      })
    );
  }

  removeEntitiesFromPlanConfigWithoutBackendUpdate(entities: Feature<Geometry, EntityProperties>[], planName: string): Observable<any> {
    return this.plans$.pipe(
      take(1),
      map(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        console.log("TP in func", targetPlan)
        if (targetPlan) {
          entities.forEach(entity => {
            if (targetPlan.planConfig[entity.id]) {
              delete targetPlan.planConfig[entity.id];
            }
          });
          this.plansSubject.next(Utils.clone(plans));
        }
        return of(null);
      })
    );
  }
  
  

  removeEntitiesFromPlanConfigWithoutSaving(entities: Feature<Geometry, EntityProperties>[], planName: string): Observable<any> {
    return this.plans$.pipe(
      take(1),
      map(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        if (targetPlan) {
          entities.forEach(entity => {
            if (targetPlan.planConfig[entity.id]) {
              delete targetPlan.planConfig[entity.id];
            }
          });
          this.plansSubject.next(Utils.clone(plans));
          return targetPlan;
        }
        throw new Error(`Plan ${planName} not found`);
      }),
    );
  }
  
  

  addParentIdToEntityInPlanConfig(entityId: string, parentId: string, planName: string): Observable<void> {
    return this.plans$.pipe(
      take(1),
      switchMap(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        console.log("TargetPlan", targetPlan)
        if (targetPlan) {
          const entityConfig = targetPlan.planConfig[entityId];
          if (entityConfig) {
            entityConfig.parentId = parentId;
            console.log("TargetPlan in addParent", targetPlan)
            console.log("Plans in addparent", Utils.clone(plans))
            this.plansSubject.next(Utils.clone(plans));
            this.projectStateStoreService.nextCurrentPlan(targetPlan)
            // Update the plan in the backend using CrudService.upsertPlanObservable()
            const upsertPlanObs = this.crud.upsertPlanObservable(targetPlan);
            return upsertPlanObs.pipe(
              take(1),
              catchError(error => {
                // If there was an error updating the plan in the backend, remove the entity from the plan config
                this.plansSubject.next(Utils.clone(plans));
                return throwError(error)
              })
            );
          }
        }
        return of(null);
      })
    );
  }

  removeParentIdFromPlanConfig(entityId: string, planName: string): Observable<void> {
    return this.plans$.pipe(
      take(1),
      mergeMap(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        if (targetPlan) {
          const targetEntity = targetPlan.planConfig[entityId];
          if (targetEntity && targetEntity.parentId) {
            delete targetEntity.parentId;
            this.plansSubject.next(Utils.clone(plans));
            this.projectStateStoreService.nextCurrentPlan(targetPlan)
            // Update the plan in the backend using CrudService.upsertPlanObservable()
            const upsertPlanObs = this.crud.upsertPlanObservable(targetPlan);
            return upsertPlanObs.pipe(
              take(1),
              catchError(error => {
                // If there was an error updating the plan in the backend, remove the entity from the plan config
                this.plansSubject.next(Utils.clone(plans));
                return throwError(error)
              })
            );
          }
        }
        return of(null);
      })
    );
  }

 
  addEntitiesToPlanConfigWithParentId(entities: Feature<Geometry, EntityProperties>[], planName: string, parentId?: string): Observable<any> {
    return this.plans$.pipe(
      take(1),
      map(plans => {
        const targetPlan = plans.find(plan => plan.planName === planName);
        if (targetPlan) {
          entities.forEach(entity => {
            if (!targetPlan.planConfig[entity.id]) {
              if (parentId) {
                targetPlan.planConfig[entity.id] = { version: 'v0' , parentId: parentId}
              } else {
                targetPlan.planConfig[entity.id] = { version: 'v0' };
              }
            }
          });
          this.plansSubject.next(Utils.clone(plans));
          this.projectStateStoreService.nextCurrentPlan(targetPlan)
          return targetPlan;
        }
        throw new Error(`Plan ${planName} not found`);
      }),
      switchMap(targetPlan => {
        const upsertPlanObs = this.crud.upsertPlanObservable(targetPlan);
        return upsertPlanObs.pipe(
          catchError(error => {
            // If there was an error updating the plan in the backend, revert the changes made to the plan config
            entities.forEach(entity => {
              delete targetPlan.planConfig[entity.id];
            });
            this.plansSubject.next(Utils.clone(this.plansSubject.getValue()));
            this.projectStateStoreService.nextCurrentPlan(targetPlan)
            return throwError(error);
          })
        );
      })
    );
  }
  
 
}
