import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ImportEntitiesActionTypes} from "../../types/types";

@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements OnInit {
  public title = 'New Param Config';
  public value = '';
  @Input() placeholder = undefined;
  @Input() objData = undefined;
  @Output() response = new EventEmitter<{}>();

  constructor() { }

  ngOnInit(): void {
  }

  handleOk(): void {
    if (this.objData) {
      this.objData.value = this.value
      this.response.next({res: this.objData})
    } else {
      this.response.next({value: this.value})
    }
  }

  handleCancel(): void {
    this.response.next(undefined);
  }

}
