import { Injectable, NgZone } from '@angular/core';
import { Feature, Geometry } from 'geojson';
import L from 'leaflet';
import { NzMessageService } from 'ng-zorro-antd/message';
import { forkJoin } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { MainPageService } from 'src/app/components/main-page/main-page.service';
import { SCOPELINE_INDEX_OLD, BLUELINE_LANDUSE } from 'src/app/config';
import { ENTITY_GEOMETRIC_TYPES, PANEL_TABS, ProjectRoles } from 'src/app/enums/enums';
import { EntityProperties } from 'src/app/models/entity-properties';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EntitiesService } from 'src/app/services/entities/entities.service';
import { MenuDataStoreService } from 'src/app/services/menuDataStore/menu-data-store.service';
import { ProjectStateStoreService } from 'src/app/services/projectStateStore/project-state-store.service';
import { StoresManagerService } from 'src/app/services/storesManagerService/stores-manager.service';
import Utils from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class GeomanControlService {

  isComment: boolean;
  isSelectionMode: boolean = false;
  isHasLayerInCoordinate: boolean;
  hasLayerInCoordinateTimer = 300;
  controlMenu;
  controlSelector;
  commentPoi;

  isShiftKeyPressed: boolean;


  snapEnabled: boolean = false;
  snapGuidesEnabled: boolean = false;

  private leafletMap: L.Map;

  geomanMeasurementOptions = {
    measurement: true,
    showTooltip: true,
    showTooltipOnHover: true,
    displayFormat: 'metric',
    totalLength: true,
    segmentLength: true,
    area: true,
    radius: true,
    perimeter: true,
    height: false,
    width: false,
    coordinates: false
  }

  iconMarkerDefault: L.DivIcon = L.divIcon({
    className: 'marker-icon',
  });

  constructor(
    private _ngZone: NgZone,
    private authService: AuthService,
    private nzMessageService: NzMessageService,
    private entitiesService: EntitiesService,
    private storesManagerService: StoresManagerService,
    private projectStateStoreService: ProjectStateStoreService,
    private menuDataStoreService: MenuDataStoreService,
  ) { }

  initControls(map: L.Map, allParams, projectName, controls): void {


    map.pm.setGlobalOptions({
      // markerStyle: { icon: this.iconMarkerDefault },
      // @ts-ignore
      measurements: this.geomanMeasurementOptions
    });

    map.on('pm:create', (e) => {
      if (e.shape === "Text") {
        return
      }
      this._ngZone.run(() => this.createEntity(map, e, allParams, projectName, controls));
      // @ts-ignore
      map.pm.Draw._setGlobalDrawMode();
    })
    map.on('pm:drawend', (e) => {
      setTimeout(() => this.isSelectionMode = false, this.hasLayerInCoordinateTimer + 1)
    });
    map.on('pm:buttonclick', e => this.toggleBar(map, e, controls));
    map.on('click', () => this.detectIfClickLayer(map));
    map.attributionControl.setPrefix(false);

    ['Line', 'Polygon', 'Rectangle', 'Circle', 'Marker'].forEach(shape => map.pm.Toolbar.changeActionsOfControl(shape, []));
    if (this.authService.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR])) {
      this.controlMenu = map.pm.Toolbar.createCustomControl({
        name: 'drawMenu',
        block: 'draw',
        title: 'Start drawing',
        className: 'drawMenuIcon',
        toggle: true,
        actions: [
          {
            text: '<div class="control-icon leaflet-pm-icon-polyline" style="width:20px;" title="Draw Line"></div>',
            onClick: () => {
              map.pm.enableDraw('Line');
              controls.drawPolyline = true;
              controls.drawMenu = false;
              map.pm.addControls(controls);
            },
          },
          {
            text: '<div class="control-icon leaflet-pm-icon-polygon" style="width:20px;" title="Draw Polygon"></div>',
            onClick: () => {
              map.pm.enableDraw('Polygon');
              controls.drawPolygon = true;
              controls.drawMenu = false;
              map.pm.addControls(controls);
            }
          },
          {
            text: '<div class="control-icon leaflet-pm-icon-rectangle" style="width:20px;" title="Draw Rectangle"></div>',
            onClick: () => {
              map.pm.enableDraw('Rectangle');
              controls.drawRectangle = true;
              controls.drawMenu = false;
              map.pm.addControls(controls);
            }
          },
          {
            text: '<div class="control-icon leaflet-pm-icon-circle" style="width:20px;" title="Draw Circle"></div>',
            onClick: () => {
              map.pm.enableDraw('Circle');
              controls.drawCircle = true;
              controls.drawMenu = false;
              map.pm.addControls(controls);
            }
          },
          {
            text: '<div class="control-icon leaflet-pm-icon-marker" style="width:20px;" title="Draw POI"></div>',
            onClick: () => {
              map.pm.enableDraw('Marker');
              // controls.drawMarker = true;
              // map.pm.addControls(controls);
            }
          }
        ]
      });

      // Modify Menu Button
      const modifyMenu = map.pm.Toolbar.createCustomControl({
        name: 'modifyMenu',
        block: 'draw',
        title: 'Modify Menu',
        className: 'modifyMenuIcon',
        toggle: true,
        actions: [
          {
            text: '<div class="control-icon rotateIcon" style="width:20px;" title="Rotate Entity"></div>',
            onClick: () => {
              this.handleRotateEntity();
            }
          },
          {
            text: '<div class="control-icon dragIcon" style="width:20px;" title="Drag Entity"></div>',
            onClick: () => {
              this.handleMoveEntity();
            }
          },
          {
            text: '<div class="control-icon duplicateIcon" style="width:20px;" title="Duplicate Entity"></div>',
            onClick: () => {
              this.handleEntityDuplication();
            }
          },
          {
            text: '<div class="control-icon scaleIcon" style="width:20px;" title="Scale Entity"></div>',
            onClick: () => {
              // @ts-ignore
              // map.pm.enableGlobalScaleMode()
              this.handleEntityScale();
            }
          }
        ]
      });
      
      // Annotate Menu Button
      const annotateMenu = map.pm.Toolbar.createCustomControl({
        name: 'annotateMenu',
        block: 'draw',
        title: 'Annotate Menu',
        className: 'annotateMenuIcon',
        toggle: true,
        actions: [
          {
            text: '<div class="control-icon commentIcon" style="width:20px;" title="Comment"></div>',
            onClick: () => {
              const userName = this.authService.getUsername();
              const userInitial = Utils.getInitials(userName);
              const backgrounColor = this.stringToColor(userName);
              const customIconSvg = this.generateSVG(backgrounColor, userInitial);
              const svgDataUrl = "data:image/svg+xml," + encodeURIComponent(customIconSvg);
              const customIcon = L.icon({
                iconUrl: svgDataUrl,
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [0, -15]
              });
              map.pm.enableDraw('Marker', { markerStyle: { icon: customIcon } });
              this.isComment = true;
            }
          },
          {
            text: '<div class="control-icon textIcon" style="width:20px;" title="Text Label"></div>',
            onClick: () => {
              map.pm.enableDraw('Text', {textOptions: {focusAfterDraw: true, text: 'fdslk;jgs'}});
              // controls.drawText = true;
              // map.pm.addControls(controls);
            }
          },
          // {
          //   text: '<div class="control-icon leaflet-pm-icon-marker" style="width:20px;" title="Draw POI"></div>',
          //   onClick: () => {
          //     map.pm.enableDraw('Marker');
          //     // controls.drawMarker = true;
          //     // map.pm.addControls(controls);
          //   }
          // }
        ]
      });

      // Extra Tools Menu Button
      const extraToolsMenu = map.pm.Toolbar.createCustomControl({
        name: 'extraToolsMenu',
        block: 'draw',
        title: 'Extra Tools Menu',
        className: 'extraToolsMenuIcon',
        toggle: true,
        actions: [
          {
            text: '<div class="control-icon mergeIcon" style="width:20px;" title="Merge-coming soon"></div>',
            onClick: () => { },
            // disabled: true
          },
          {
            text: '<div class="control-icon skeletonIcon" style="width:20px;" title="Skeleton-coming soon"></div>',
            onClick: () => { },
            // disabled: true
          },
          {
            text: '<div class="control-icon junctionIcon" style="width:20px;" title="Juction-coming-soon"></div>',
            onClick: () => { },
            // disabled: true
          }
        ]
      });

      const buildingTemplateMenu = map.pm.Toolbar.createCustomControl({
        name: 'Building Template Menu',
        block: 'custom',
        title: 'Building Template - coming soon',
        className: 'buildingTamplateIcon',
        toggle: false
      });



      map.pm.addControls({
        ...controls,
        drawMenu: true,
        modifyMenu: true,
        extraToolsMenu: true
      });

    }

    // if (this.authService.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR]) || Object.values(currentEntities).length > 0) {
    //   this.controlSelector = map.pm.Toolbar.createCustomControl({
    //     name: 'selector',
    //     block: 'draw',
    //     title: 'Select entities',
    //     toggle: true,
    //     className: 'selectMenuIcon',
    //     onClick: () => { // causes Maximum call stack size exceeded error
    //       this.startSelectionMode(map);
    //     },
    //     // afterClick: () => this.startSelectionMode(),
    //     //   // TODO: For some odd reason when we enableGlobalDragMode, this event fires TWICE.
    //     //   // Which closes them menu, preventing us from showing the "cancel" button.
    //     //   // map.pm.enableGlobalDragMode();
    //     // },
    //     actions: [],
    //   });
    //   map.pm.addControls(controls);
    // }

     // Second block with snapping options
    //  const snapControl = map.pm.Toolbar.createCustomControl({
    //   name: 'snapOption',
    //   block: 'options',
    //   title: 'Snap Option',
    //   toggle: true,
    //   className: 'snapOptionIcon',
    //   onClick: () => {
    //     this.snapEnabled = !this.snapEnabled;
    //     map.pm.setGlobalOptions({ snappable: this.snapEnabled });
    //   },
    //   actions: []
    // });

    // const snapGuidesControl = map.pm.Toolbar.createCustomControl({
    //   name: 'snapGuidesCustomOption',
    //   block: 'options',
    //   title: 'Snap Guides Option',
    //   toggle: false,
    //   className: 'snapGuidesOptionIcon',
    //   onClick: () => {
    //     this.snapGuidesEnabled = !this.snapGuidesEnabled;
    //     // @ts-ignore
    //     map.pm.setGlobalOptions({ showSnapGuides: this.snapGuidesEnabled});
    //     console.log(map.pm.getGlobalOptions())
    //   },
    //   actions: []
    // });

    // map.pm.addControls({
    //   snapOption: true,
    //   snapGuidesCustomOption: true
    // });

    map.on('pm:globalrotatemodetoggled', (e) => {

      map.eachLayer((layer) => {
        // @ts-ignore
        if (layer.id) {
            // @ts-ignore
            if ((!this.selectedEntitiesMap[layer.id] || this.currentPlan.isLock) ||(this.entitiesService.entityIsLock(layer.id) || !this.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR]))) {
              // @ts-ignore
              layer.pm.disableRotate()
          }
        }
      });
    });

    this.leafletMap = map;
  }

  // handleEntityDuplication() {
  //   this.menuDataStoreService.menuData$.pipe(
  //     take(1),  // Get the value of menuData once
  //     switchMap(menuData =>
  //       this.projectStateStoreService.selectedEntities$.pipe(
  //         take(1),  // Get the value of selectedEntities once
  //         map(selectedEntities => ({ selectedEntities, menuData }))  // Combine both values
  //       )
  //     )
  //   ).subscribe(({ selectedEntities, menuData }) => {
  //     if (selectedEntities.length === 0) {
  //       this.nzMessageService.warning('Please select an entity to duplicate');
  //     } else {
  //       const selectedEntity = selectedEntities[0];
  //       this.entitiesService.duplicateEntity(selectedEntity, this.currentEntites, this.currentPlan, menuData);
  //     }
  //   });
  // }

  handleEntityDuplication() {
    forkJoin({
      menuData: this.menuDataStoreService.menuData$.pipe(take(1)),
      selectedEntities: this.projectStateStoreService.selectedEntities$.pipe(take(1)),
      currentEntities: this.projectStateStoreService.getCurrentPlanEntities().pipe(take(1)),
      currentPlan: this.projectStateStoreService.currentPlan$.pipe(take(1))
    }).subscribe(({ selectedEntities, menuData, currentEntities, currentPlan }) => {
      if (selectedEntities.length === 0) {
        this.nzMessageService.warning('Please select an entity to duplicate');
      } else {
        const selectedEntity = selectedEntities[0];
        this.entitiesService.duplicateEntity(selectedEntity, currentEntities, currentPlan, menuData);
      }
    });
  }
  

  handleEntityScale() {
    this.projectStateStoreService.selectedEntities$.pipe(take(1)).subscribe(selectedEntities => {
      if (selectedEntities.length === 0) {
        this.nzMessageService.warning('Please select an entity to scale');
      } else {
        const selectedEntity = selectedEntities[0];
        this.leafletMap.eachLayer(layer => {
          // @ts-ignore
          if (layer.id === selectedEntity.id) {
            // @ts-ignore
            layer.pm.enableScale();
          }
        });
      }
    });
  }

  handleMoveEntity(){
    this.projectStateStoreService.selectedEntities$.pipe(take(1)).subscribe(selectedEntities => {
      if (selectedEntities.length === 0) {
        this.nzMessageService.warning('Please select an entity to move');
      } else {
        const selectedEntity = selectedEntities[0];
        this.leafletMap.eachLayer(layer => {
          // @ts-ignore
          if (layer.id === selectedEntity.id) {
            // @ts-ignore
            layer.pm.enableLayerDrag();
          }
        });
      }
    });

  }

  handleRotateEntity(){
    // map.pm.enableGlobalRotateMode();
    this.projectStateStoreService.selectedEntities$.pipe(take(1)).subscribe(selectedEntities => {
      if (selectedEntities.length === 0) {
        this.nzMessageService.warning('Please select an entity to rotate');
      } else {
        const selectedEntity = selectedEntities[0];
        this.leafletMap.eachLayer(layer => {
          // @ts-ignore
          if (layer.id === selectedEntity.id) {
            // @ts-ignore
            layer.pm.enableRotate();
          }
        });
      }
    });
  }

  createEntity(map, layer, allParams, projectName, controls) {
    layer.layer.remove();
    const shape = { type: layer.shape };   
    if (layer.layer.getRadius) {
      Object.assign(shape, { radius: layer.layer.getRadius() });
      shape.type = ENTITY_GEOMETRIC_TYPES.CIRCLE;
    }

    const options: {shape, params?: {}, isImport?: boolean} = { shape };

    if (this.isComment) {
      Object.assign(options, {params: {entityType:'comment', createdBy: this.authService.getUsername()}});
      this.isComment = false; // Reset the flag
    }

    if (this.isSelectionMode) {
      this.entitiesService.featuresWithin(layer.layer.toGeoJSON());
    } else {
      const entity = this.entitiesService.createEntity(layer.layer.toGeoJSON(), options, allParams, projectName);

      // check visibility state
      const type = this.entitiesService.getEntityType(entity)
      this.entitiesService.checkEntityTypeVisibility(type)
      
      if (entity.properties.type === ENTITY_GEOMETRIC_TYPES.POINT) {
        map.pm.disableGlobalEditMode();
      }
      
      this.entitiesService.generateDefaultEntityNameObservable(entity, {}).pipe(
        take(1),
        ).subscribe(name => {
        entity.properties.name = name;
  
        this.onClickEntity(map, entity, true, false); 
        this.storesManagerService.addNewEntityToProjectAndPlanConfig3(entity);
        this.resetDrawMenu(map, controls);
        this.storesManagerService.setPanelTab(PANEL_TABS.Edit_Layer, false);
      });
    }
  }

  onClickEntity( map: L.Map, entity: Feature<Geometry, EntityProperties>, isNew?: boolean, recordInHistory: boolean = true) {
    if (map.pm.globalDrawModeEnabled()) {
      return;
    }
    if (entity.id === SCOPELINE_INDEX_OLD || entity.properties.landUse === BLUELINE_LANDUSE) {
      return;
    }
    this.storesManagerService.setPanelTab(PANEL_TABS.Edit_Layer, false);
    this._ngZone.run(() => {
      const toRecordInHistory = this.storesManagerService.managerActionFlag ? false : recordInHistory;  // a flag not to record history while updating entity
      this.storesManagerService.setSelectedEntities([entity], this.isShiftKeyPressed, toRecordInHistory); 
    });
    this.storesManagerService.managerActionFlag = false
    if (!isNew) {
      setTimeout(() => this.isHasLayerInCoordinate = false, this.hasLayerInCoordinateTimer + 100);
    }
  }


  toggleBar(map, e, controls) {
    if (!map.pm.globalDrawModeEnabled()) {
      return;
    }
    if (['drawPolyline', 'drawPolygon', 'drawRectangle', 'drawCircle', 'drawMenu', 'comment', 'drawText'].includes(e.btnName)) {
      setTimeout(() => this.resetDrawMenu(map, controls));
    }
  }

  resetDrawMenu(map: L.Map, controls) {
    controls.drawPolyline = false;
    controls.drawPolygon = false;
    controls.drawRectangle = false;
    controls.drawCircle = false;
    controls.drawMarker = false;
    controls.drawText = false;
    controls.drawMenu = true;
    map.pm.disableDraw();
    map.pm.addControls(controls);
    if (this.authService.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR])) {
      this.controlMenu.toggle(false);
    }

    // this.controlSelector.toggle(false);
  }

  detectIfClickLayer(map: L.Map) {
    setTimeout(() => {
      if (!this.isHasLayerInCoordinate) {
        if (!map.pm.globalDrawModeEnabled() && !this.isSelectionMode) {
          this.storesManagerService.setSelectedEntities([], false, false)
        }
      }
    }, this.hasLayerInCoordinateTimer);
  }

  startSelectionMode(map: L.Map) {
    if (this.isSelectionMode) {
      return;
    }
    this.isSelectionMode = true;
    map.pm.enableDraw('Rectangle', { snappable: true });
  }

  stringToColor(name: string = 'Urban Dashboard'): string {
    // Hash function to convert the name into a number
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Base angle for the triadic color scheme
    const baseAngle = 120; // 120 degrees separation for triadic colors

    // Use the hash to determine the starting angle on the color wheel
    let hue = hash % 360; // Hue value between 0 and 359

    // Convert the hue to RGB using a simple algorithm
    const rgb = this.hueToRGB(hue);

    return this.rgbToHex(...rgb);
  }

  hueToRGB(hue: number): [number, number, number] {
      const chroma = 0.7; // Chroma fixed for vibrant colors
      const hPrime = hue / 60;
      const x = chroma * (1 - Math.abs((hPrime % 2) - 1));

      let r = 0, g = 0, b = 0;
      if(hPrime >= 0 && hPrime < 1) { r = chroma; g = x; }
      else if(hPrime >= 1 && hPrime < 2) { r = x; g = chroma; }
      else if(hPrime >= 2 && hPrime < 3) { g = chroma; b = x; }
      else if(hPrime >= 3 && hPrime < 4) { g = x; b = chroma; }
      else if(hPrime >= 4 && hPrime < 5) { r = x; b = chroma; }
      else if(hPrime >= 5 && hPrime <= 6) { r = chroma; b = x; }

      // Adjust lightness
      const lightnessAdjustment = 0.5 - (chroma / 2);
      r += lightnessAdjustment;
      g += lightnessAdjustment;
      b += lightnessAdjustment;

      return [r * 255, g * 255, b * 255];
  }

  // Helper function to convert RGB to Hex
  rgbToHex(r: number, g: number, b: number): string {
    return "#" + [r, g, b].map(x => {
        const hex = Math.round(x).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }).join('');
  }

  generateSVG(color, text) {
    let svgTemplate = `<svg width="42" height="44" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3192_697)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 35.8888C30.3888 35.8888 38 28.2776 38 18.8888C38 9.49995 30.3888 1.88879 21 1.88879C11.6112 1.88879 4 9.49995 4 18.8888C4 18.8888 4 18.8888 4 18.8889V35.8889H21V35.8888Z" fill="white"/>
    </g>
    <circle cx="20.9998" cy="18.8889" r="15.1111" fill="{{color}}"/>
    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white">{{text}}</text>
    <defs>
    <filter id="filter0_d_3192_697" x="0.0769231" y="1.88879" width="41.8462" height="41.8463" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3.92308"/>
    <feGaussianBlur stdDeviation="1.96154"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3192_697"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3192_697" result="shape"/>
    </filter>
    </defs>
    </svg>`; // Your SVG string with placeholders
    svgTemplate = svgTemplate.replace("{{color}}", color);
    svgTemplate = svgTemplate.replace("{{text}}", text);
    return svgTemplate;
  }

}
