export const urbanEntityTypeColors = {
  'marketable lot': '#FFB33F',
  'public lot': '#AE8060',
  'right of right of road': '#E95D59',
  'open area': '#5EA75F',
  green: '#9BD647',
  infrastructure: '#b5838d',
  'water body': '#15A3C2',
  other: '#C8C7C1'
};

// export const mavatUrbanDashboard = {
//   "10": "marketable lot",
//   "20": "marketable lot",
//   "30": "marketable lot",
//   "40": "marketable lot",
//   "50": "marketable lot",
//   "60": "marketable lot",
//   "70": "marketable lot",
//   "80": "marketable lot",
//   "90": "marketable lot",
//   "100": "marketable lot",
//   "110": "marketable lot",
//   "120": "marketable lot",
//   "130": "marketable lot",
//   "140": "marketable lot",
//   "142": "marketable lot",
//   "144": "marketable lot",
//   "146": "marketable lot",
//   "150": "marketable lot",
//   "160": "marketable lot",
//   "170": "marketable lot",
//   "200": "marketable lot",
//   "210": "marketable lot",
//   "220": "marketable lot",
//   "230": "marketable lot",
//   "240": "marketable lot",
//   "250": "marketable lot",
//   "260": "marketable lot",
//   "280": "other",
//   "290": "marketable lot",
//   "300": "marketable lot",
//   "400": "public lot",
//   "410": "public lot",
//   "420": "public lot",
//   "430": "public lot",
//   "440": "public lot",
//   "450": "public lot",
//   "460": "public lot",
//   "600": "marketable lot",
//   "610": "marketable lot",
//   "620": "marketable lot",
//   "630": "marketable lot",
//   "650": "open area",
//   "660": "open area",
//   "661": "open area",
//   "670": "green",
//   "680": "green",
//   "690": "green",
//   "700": "green",
//   "710": "open area",
//   "720": "open area",
//   "730": "open area",
//   "740": "water body",
//   "750": "marketable lot",
//   "760": "right of road",
//   "770": "open area",
//   "780": "green",
//   "800": "infrastructure",
//   "810": "infrastructure",
//   "820": "right of road",
//   "830": "right of road",
//   "840": "right of road",
//   "850": "right of road",
//   "855": "infrastructure",
//   "860": "green",
//   "870": "right of road",
//   "880": "infrastructure",
//   "890": "infrastructure",
//   "900": "infrastructure",
//   "902": "infrastructure",
//   "910": "infrastructure",
//   "920": "infrastructure",
//   "930": "right of road",
//   "931": "infrastructure",
//   "932": "infrastructure",
//   "935": "infrastructure",
//   "936": "infrastructure",
//   "937": "infrastructure",
//   "940": "right of road",
//   "950": "other",
//   "955": "infrastructure",
//   "960": "other",
//   "970": "other",
//   "980": "other",
//   "990": "other",
//   "995": "other",
//   "996": "other",
//   "997": "other",
//   "999": "other",
//   "1000": "marketable lot",
//   "1001": "marketable lot",
//   "1050": "marketable lot",
//   "1051": "marketable lot",
//   "1200": "marketable lot",
//   "1201": "marketable lot",
//   "1211": "marketable lot",
//   "1212": "marketable lot",
//   "1221": "marketable lot",
//   "1222": "marketable lot",
//   "1250": "marketable lot",
//   "1300": "marketable lot",
//   "1311": "marketable lot",
//   "1312": "marketable lot",
//   "1321": "marketable lot",
//   "1322": "marketable lot",
//   "1350": "marketable lot",
//   "1352": "marketable lot",
//   "1410": "marketable lot",
//   "1411": "marketable lot",
//   "1420": "marketable lot",
//   "1421": "marketable lot",
//   "1430": "marketable lot",
//   "1431": "marketable lot",
//   "1440": "marketable lot",
//   "1441": "marketable lot",
//   "1460": "marketable lot",
//   "1461": "marketable lot",
//   "1470": "marketable lot",
//   "1471": "marketable lot",
//   "1480": "marketable lot",
//   "1481": "marketable lot",
//   "1482": "marketable lot",
//   "1483": "marketable lot",
//   "1484": "marketable lot",
//   "1485": "marketable lot",
//   "1488": "marketable lot",
//   "1489": "marketable lot",
//   "1490": "marketable lot",
//   "1491": "marketable lot",
//   "1492": "marketable lot",
//   "1493": "marketable lot",
//   "1494": "marketable lot",
//   "1495": "marketable lot",
//   "1500": "marketable lot",
//   "1502": "marketable lot",
//   "1504": "marketable lot",
//   "1510": "marketable lot",
//   "1520": "marketable lot",
//   "1530": "marketable lot",
//   "1540": "marketable lot",
//   "1550": "marketable lot",
//   "1560": "marketable lot",
//   "1562": "marketable lot",
//   "1563": "marketable lot",
//   "1564": "marketable lot",
//   "1565": "marketable lot",
//   "1570": "marketable lot",
//   "1572": "marketable lot",
//   "1574": "marketable lot",
//   "1576": "marketable lot",
//   "1578": "marketable lot",
//   "1579": "marketable lot",
//   "1580": "marketable lot",
//   "1590": "marketable lot",
//   "1600": "marketable lot",
//   "1602": "public lot",
//   "1604": "public lot",
//   "1610": "public lot",
//   "1620": "marketable lot",
//   "1630": "marketable lot",
//   "1640": "marketable lot",
//   "1650": "public lot",
//   "1660": "green",
//   "1670": "green",
//   "1680": "green",
//   "1681": "green",
//   "1690": "other",
//   "other": "other"
// }

// new mavatUrbanDashboard 
export const mavatUrbanDashboard = {
  "10": {
    "urbanLandUse": "residential", 
    "mavatNameHebrew": "מגורים", 
    "pattern": "10.png",
    "accessType": "private"
  },
  "20": {"urbanLandUse": "residential", "mavatNameHebrew": "מגורים א", "pattern": "20.png", "accessType": "private"},
  "60": {"urbanLandUse": "residential", "mavatNameHebrew": "מגורים ב", "pattern": "60.png", "accessType": "private"},
  "100": {"urbanLandUse": "residential", "mavatNameHebrew": "מגורים ג", "pattern": "100.png", "accessType": "private"},
  "140": {"urbanLandUse": "residential", "mavatNameHebrew": "מגורים ד", "pattern": "140.png", "accessType": "private"},
  "145": {"urbanLandUse": "residential", "mavatNameHebrew": "מגורים ה", "pattern": "145.png", "accessType": "private"},
  "150": {"urbanLandUse": "residential", "mavatNameHebrew": "דיור מיוחד", "pattern": "150.png", "accessType": "private"},
  "160": {"urbanLandUse": "residential", "mavatNameHebrew": "מגורים ביישוב כפרי", "pattern": "160.png", "accessType": "private"},
  "170": {"urbanLandUse": "agriculture", "mavatNameHebrew": "משק עזר", "pattern": "170.png", "accessType": "private"},
  "200": {"urbanLandUse": "office", "mavatNameHebrew": "תעסוקה", "pattern": "200.png", "accessType": "private"},
  "210": {"urbanLandUse": "retail", "mavatNameHebrew": "מסחר", "pattern": "210.png", "accessType": "private"},
  "220": {"urbanLandUse": "office", "mavatNameHebrew": "משרדים", "pattern": "220.png", "accessType": "private"},
  "230": {"urbanLandUse": "industrial", "mavatNameHebrew": "תעשיה", "pattern": "230.png", "accessType": "private"},
  "240": {"urbanLandUse": "industrial", "mavatNameHebrew": "אחסנה", "pattern": "240.png", "accessType": "private"},
  "250": {"urbanLandUse": "industrial", "mavatNameHebrew": "תעשיה עתירת ידע", "pattern": "250.png", "accessType": "private"},
  "260": {"urbanLandUse": "industrial", "mavatNameHebrew": "תעשיה קלה ומלאכה", "pattern": "260.png", "accessType": "private"},
  "280": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "מתקנים הנדסיים", "pattern": "280.png", "accessType": "private"},
  "290": {"urbanLandUse": "mix_use", "mavatNameHebrew": "עירוני מעורב", "pattern": "290.png", "accessType": "private"},
  "300": {"urbanLandUse": "agriculture", "mavatNameHebrew": "מבני משק", "pattern": "300.png", "accessType": "private"},
  "750": {"urbanLandUse": "retail", "mavatNameHebrew": "גן אירועים", "pattern": "750.png", "accessType": "private"},
  "972": {"urbanLandUse": "industrial", "mavatNameHebrew": "מרכז לוגיסטי", "pattern": "972.png", "accessType": "private"},
  "400": {"urbanLandUse": "public_buildings", "mavatNameHebrew": "מבנים ומוסדות ציבור", "pattern": "400.png", "accessType": "private"},
  "600": {"urbanLandUse": "hotel", "mavatNameHebrew": "ֿתיירות", "pattern": "600.png", "accessType": "private"},
  "610": {"urbanLandUse": "hotel", "mavatNameHebrew": "אירוח כפרי", "pattern": "610.png", "accessType": "private"},
  "620": {"urbanLandUse": "hotel", "mavatNameHebrew": "מלונאות אכסון מלונאי", "pattern": "620.png", "accessType": "private"},
  "630": {"urbanLandUse": "hotel", "mavatNameHebrew": "אטרקציות תיירותיות", "pattern": "630.png", "accessType": "private"},
  "650": {"urbanLandUse": "park", "mavatNameHebrew": "שטחים פתוחים", "pattern": "650.png", "accessType": "private"},
  "660": {"urbanLandUse": "agriculture", "mavatNameHebrew": "קרקע חקלאית", "pattern": "660.png", "accessType": "private"},
  "661": {"urbanLandUse": "agriculture", "mavatNameHebrew": "חקלאי פתוח", "pattern": "661.png", "accessType": "private"},
  "670": {"urbanLandUse": "park", "mavatNameHebrew": "שטח ציבורי פתוח", "pattern": "670.png", "accessType": "public"},
  "680": {"urbanLandUse": "green_area", "mavatNameHebrew": "פרטי פתוח", "pattern": "680.png", "accessType": "public"},
  "690": {"urbanLandUse": "park", "mavatNameHebrew": "ספורט ונופש", "pattern": "690.png", "accessType": "public"},
  "700": {"urbanLandUse": "park", "mavatNameHebrew": "פארק/ גן ציבורי", "pattern": "700.png", "accessType": "public"},
  "710": {"urbanLandUse": "park", "mavatNameHebrew": "יער", "pattern": "710.png", "accessType": "public"},
  "711": {"urbanLandUse": "park", "mavatNameHebrew": "יער נטע אדם", "pattern": "711.png", "accessType": "public"},
  "712": {"urbanLandUse": "park", "mavatNameHebrew": "יער טבעי", "pattern": "712.png", "accessType": "public"},
  "713": {"urbanLandUse": "park", "mavatNameHebrew": "יער פארק", "pattern": "713.png", "accessType": "public"},
  "5707": {"urbanLandUse": "park", "mavatNameHebrew": "אתר הנצחה", "pattern": "5707.png", "accessType": "public"},
  "720": {"urbanLandUse": "park", "mavatNameHebrew": "שמורת טבע", "pattern": "720.png", "accessType": "public"},
  "730": {"urbanLandUse": "park", "mavatNameHebrew": "גן לאומי", "pattern": "730.png", "accessType": "public"},
  "740": {"urbanLandUse": "water", "mavatNameHebrew": "נחל/ תעלת נחל", "pattern": "740.png", "accessType": "public"},
  "741": {"urbanLandUse": "water", "mavatNameHebrew": "אתר איגום ו/או החדרה", "pattern": "741.png", "accessType": "public"},
  "760": {"urbanLandUse": "pedestrian_path", "mavatNameHebrew": "טיילת", "pattern": "760.png", "accessType": "public"},
  "770": {"urbanLandUse": "park", "mavatNameHebrew": "חוף רחצה", "pattern": "770.png", "accessType": "public"},
  "773": {"urbanLandUse": "water", "mavatNameHebrew": "מרחב ימי פתוח", "pattern": "773.png", "accessType": "public"},
  "780": {"urbanLandUse": "park", "mavatNameHebrew": "כיכר עירונית", "pattern": "780.png", "accessType": "public"},
  "800": {"urbanLandUse": "road_new", "mavatNameHebrew": "תחבורה", "pattern": "800.png", "accessType": "public"},
  "810": {"urbanLandUse": "road_new", "mavatNameHebrew": "מרכז תחבורה", "pattern": "810.png", "accessType": "public"},
  "903": {"urbanLandUse": "road_new", "mavatNameHebrew": "דרך לתחבורה ציבורית", "pattern": "903.png", "accessType": "public"},
  "902": {"urbanLandUse": "road_new", "mavatNameHebrew": "תחנת תחבורה ציבורית"}, "pattern": "902.png", "accessType": "public",
  "820": {"urbanLandUse": "road_existing", "mavatNameHebrew": "דרך מאושרת", "pattern": "820.png", "accessType": "public"},
  "830": {"urbanLandUse": "road_new", "mavatNameHebrew": "דרך מוצעת", "pattern": "830.png", "accessType": "public"},
  "840": {"urbanLandUse": "road_new", "mavatNameHebrew": "דרך משולבת", "pattern": "840.png", "accessType": "public"},
  "850": {"urbanLandUse": "road_new", "mavatNameHebrew": "דרך ו/או טיפול נופי", "pattern": "850.png", "accessType": "public"},
  "855": {"urbanLandUse": "road_new", "mavatNameHebrew": "מסילה ו/או טיפול נופי", "pattern": "855.png", "accessType": "public"},
  "880": {"urbanLandUse": "road_existing", "mavatNameHebrew": "מסילת ברזל מאושרת", "pattern": "880.png", "accessType": "public"},
  "890": {"urbanLandUse": "road_new", "mavatNameHebrew": "מסילת ברזל מוצעת", "pattern": "890.png", "accessType": "public"},
  "900": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "שטח לתפעול מסילה", "pattern": "900.png", "accessType": "public"},
  "971": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "שדה תעופה", "pattern": "971.png", "accessType": "public"},
  "860": {"urbanLandUse": "pedestrian_path", "mavatNameHebrew": "שביל", "pattern": "860.png", "accessType": "public"},
  "870": {"urbanLandUse": "road_new", "mavatNameHebrew": "חניון", "pattern": "870.png", "accessType": "public"},
  "940": {"urbanLandUse": "road_new", "mavatNameHebrew": "דרך נופית", "pattern": "940.png", "accessType": "public"},
  "910": {"urbanLandUse": "road_new", "mavatNameHebrew": "תחנת תדלוק", "pattern": "910.png", "accessType": "public"},
  "920": {"urbanLandUse": "road_new", "mavatNameHebrew": "מרכז להדרכת נהיגה", "pattern": "920.png", "accessType": "public"},
  "930": {"urbanLandUse": "road_new", "mavatNameHebrew": "מפגש דרך- מסילה", "pattern": "930.png", "accessType": "public"},
  "933": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "נמל", "pattern": "933.png", "accessType": "public"},
  "931": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "מעגנה תחום יבשתי", "pattern": "931.png", "accessType": "public"},
  "932": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "מעגנה תחום ימי", "pattern": "932.png", "accessType": "public"},
  "935": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "נמל תעופה כללי", "pattern": "935.png", "accessType": "public"},
  "936": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "נמל תעופה מסלולים", "pattern": "936.png", "accessType": "public"},
  "937": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "נמל תעופה אזורי שירות", "pattern": "936.png", "accessType": "public"},
  "950": {"urbanLandUse": "other_public", "mavatNameHebrew": "שטח לתכנון בעתיד", "pattern": "950.png", "accessType": "public"},
  "955": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "רצועת תשתיות", "pattern": "955.png", "accessType": "public"},
  "960": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "כריה חציבה", "pattern": "960.png", "accessType": "public"},
  "970": {"urbanLandUse": "infrastructure", "mavatNameHebrew": "פסולת", "pattern": "970.png", "accessType": "public"},
  "980": {"urbanLandUse": "park", "mavatNameHebrew": "בית קברות", "pattern": "980.png", "accessType": "public"},
  "990": {"urbanLandUse": "other_public", "mavatNameHebrew": "מחנה צבאי", "pattern": "990.png", "accessType": "public"},
  "994": {"urbanLandUse": "other_public", "mavatNameHebrew": "גבול הודעה בדבר הכנת תוכנית", "pattern": "994.png", "accessType": "public"},
  "995": {"urbanLandUse": "other_public", "mavatNameHebrew": "ייעוד על פי תוכנית מאושרת אחרת", "pattern": "995.png", "accessType": "public"},
  "996": {"urbanLandUse": "other_public", "mavatNameHebrew": "מגבלות בניה ופיתוח", "pattern": "996.png", "accessType": "public"},
  "997": {"urbanLandUse": "other_public", "mavatNameHebrew": "מגבלות בניה ופיתוח ב", "pattern": "997.png", "accessType": "public"},
  "999": {"urbanLandUse": "other_public", "mavatNameHebrew": "שטח שהתוכנית אינו חלה עליו", "pattern": "999.png", "accessType": "public"},
  "1000": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ומסחר", "pattern": "1000.png", "accessType": "private"},
  "1100": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ותעסוקה", "pattern": "1100.png", "accessType": "private"},
  "1050": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ותעסוקה", "pattern": "1050.png", "accessType": "private"},
  "1200": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ומשרדים", "pattern": "1200.png", "accessType": "private"},
  "1211": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ותעשיה עתירת ידע", "pattern": "1211.png", "accessType": "private"},
  "1221": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ומתקנים הנדסיים", "pattern": "1221.png", "accessType": "private"},
  "1250": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ומבנים ומוסדות ציבור", "pattern": "1250.png", "accessType": "private"},
  "1311": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ותיירות", "pattern": "1311.png", "accessType": "private"},
  "1321": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ותחבורה", "pattern": "1321.png", "accessType": "private"},
  "1350": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ודיור מיוחד", "pattern": "1350.png", "accessType": "private"},
  "1352": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ואירוח כפרי", "pattern": "1352.png", "accessType": "private"},
  "1410": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר, מבנים ומוסדות ציבור", "pattern": "1410.png", "accessType": "private"},
  "1420": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ותיירות", "pattern": "1420.png", "accessType": "private"},
  "1430": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ומתקנים הנדסיים", "pattern": "1430.png", "accessType": "private"},
  "1440": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ותחבורה", "pattern": "1440.png", "accessType": "private"},
  "1460": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ומעגנה", "pattern": "1460.png", "accessType": "private"},
  "1470": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה ותיירות", "pattern": "1470.png", "accessType": "private"},
  "1480": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה, מבנים ומוסדות ציבור", "pattern": "1480.png", "accessType": "private"},
  "1482": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה ומתקנים הנדסיים", "pattern": "1482.png", "accessType": "private"},
  "1484": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה ותחבורה", "pattern": "1484.png", "accessType": "private"},
  "1488": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה ומעגנה", "pattern": "1488.png", "accessType": "private"},
  "1490": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תיירות ומתקנים הנדסיים", "pattern": "1490.png", "accessType": "private"},
  "1492": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תיירות, מבנים ומוסדות ציבור", "pattern": "1492.png", "accessType": "private"},
  "1494": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תיירות ותחבורה", "pattern": "1494.png", "accessType": "private"},
  "1003": {"urbanLandUse": "mix_use", "mavatNameHebrew": "דיור מיוחד ותעסוקה", "pattern": "1003.png", "accessType": "private"},
  "1001": {"urbanLandUse": "mix_use", "mavatNameHebrew": "דיור מיוחד ומסחר", "pattern": "1001.png", "accessType": "private"},
  "1300": {"urbanLandUse": "mix_use", "mavatNameHebrew": "דיוק מיוחד ומבנים ומוסדות ציבור", "pattern": "1300.png", "accessType": "private"},
  "1500": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותעשיה", "pattern": "1500.png", "accessType": "private"},
  "1502": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותעסוקה", "pattern": "1510.png", "accessType": "private"},
  "1504": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ומתקנים הנדסיים", "pattern": "1504.png", "accessType": "private"},
  "1510": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותחבורה", "pattern": "1510.png", "accessType": "private"},
  "1520": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ומשרדים", "pattern": "1520.png", "accessType": "private"},
  "1530": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותעשיה עתירת ידע", "pattern": "1530.png", "accessType": "private"},
  "1540": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעשיה קלה ומלאכה", "pattern": "1540.png", "accessType": "private"},
  "1550": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ומבני ומוסדות ציבור", "pattern": "1550.png", "accessType": "private"},
  "1560": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותיירות", "pattern": "1560.png", "accessType": "private"},
  "1562": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעסוקה ותיירות", "pattern": "1562.png", "accessType": "private"},
  "1563": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תחבורה ותיירות", "pattern": "1563.png", "accessType": "private"},
  "1564": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ואירוח כפרי", "pattern": "1564.png", "accessType": "private"},
  "1565": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר וספורט ונופש", "pattern": "1565.png", "accessType": "private"},
  "1570": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ואחסנה", "pattern": "1570.png", "accessType": "private"},
  "1572": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעסוקה ומבנים הנדסיים", "pattern": "1572.png", "accessType": "private"},
  "1574": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעסוקה ותחבורה", "pattern": "1574.png", "accessType": "private"},
  "1576": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעסוקה ומוסדות ציבור", "pattern": "1576.png", "accessType": "private"},
  "1578": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תיירות, מבנים ומוסדות ציבור", "pattern": "1578.png", "accessType": "private"},
  "1579": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תחבורה, מבנים ומוסדות ציבור", "pattern": "1579.png", "accessType": "private"},
  "1580": {"urbanLandUse": "mix_use", "mavatNameHebrew": "תעשיה, תעשיה קלה ומלאכה", "pattern": "1580.png", "accessType": "private"},
  "1590": {"urbanLandUse": "mix_use", "mavatNameHebrew": "תעסוקה ותחבורה", "pattern": "1590.png", "accessType": "private"},
  "1600": {"urbanLandUse": "mix_use", "mavatNameHebrew": "תעסוקה ומבנים ומוסדות ציבור", "pattern": "1600.png", "accessType": "private"},
  "1602": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מבנים ומוסדות ציבור ומתקנים הנדסיים", "pattern": "1602.png", "accessType": "private"},
  "1604": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מבנים ומוסדות ציבור ותחבורה", "pattern": "1604.png", "accessType": "private"},
  "1610": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מבנים ומוסדות ציבור ומשרדים", "pattern": "1610.png", "accessType": "private"},
  "1620": {"urbanLandUse": "mix_use", "mavatNameHebrew": "תעסוקה ומבנים ומוסדות ציבור ותיירות", "pattern": "1620.png", "accessType": "private"},
  "1630": {"urbanLandUse": "mix_use", "mavatNameHebrew": "תעשיה ומשרדים", "pattern": "1630.png", "accessType": "private"},
  "1640": {"urbanLandUse": "mix_use", "mavatNameHebrew": "תעשיה ואחסנה", "pattern": "1640.png", "accessType": "private"},
  "1650": {"urbanLandUse": "mix_use", "mavatNameHebrew": "מבנים ומוסדות ציבור ותיירות", "pattern": "1650.png", "accessType": "private"},
  "1660": {"urbanLandUse": "mix_use", "mavatNameHebrew": "שטחים פתוחים ומתקניים הנדסיים", "pattern": "1660.png", "accessType": "private"},
  "1670": {"urbanLandUse": "mix_use", "mavatNameHebrew": "שטחים פתוחים מבנים ומוסדות ציבור", "pattern": "1670.png", "accessType": "private"},
  "1680": {"urbanLandUse": "mix_use", "mavatNameHebrew": "קרקע חקלאית ומתקנים הנדסיים", "pattern": "1680.png", "accessType": "private"},
  "1681": {"urbanLandUse": "mix_use", "mavatNameHebrew": "קרקע חקלאית ונופש כפרי", "pattern": "1681.png", "accessType": "private"},
  "1690": {"urbanLandUse": "mix_use", "mavatNameHebrew": "כריה, חציבה ופסולת", "pattern": "1690.png", "accessType": "private"}

}
