import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MENU_DATA } from 'src/app/configMenuData';

@Injectable({
  providedIn: 'root'
})
export class MenuDataStoreService implements OnDestroy {

  menuData;
  menuDataSub: Subscription;

  private menuDataSubject:BehaviorSubject<any> = new BehaviorSubject<any>(MENU_DATA)
  menuData$: Observable<any> = this.menuDataSubject.asObservable()

  constructor() {
    this.menuDataSub = this.menuData$
      .pipe(
        filter(data => data !== null)
      ).subscribe(menuData => this.menuData = menuData);
  }

  ngOnDestroy() {
    this.menuDataSub.unsubscribe()
  }

  nextMenuData(menuData: any) {
    this.menuDataSubject.next(menuData);
  }

  getValue() {
    return this.menuDataSubject.getValue();
  }

  menuDataToTurnInto(): Observable<any> {
    return this.menuData$.pipe(
      map(menuData => {
        const result = {};
        Object.keys(menuData).forEach(layer => {
          const landUses = menuData[layer].landUse;
          result[layer] = {};
          Object.keys(landUses).forEach(type => {
            const subCategory = landUses[type].subCategory || 'default';
            if (!result[layer][subCategory]) {
              result[layer][subCategory] = [];
            }
            result[layer][subCategory].push(landUses[type].displayName);
          });

          // Move elements containing 'other' to the end of each subCategory array (e.g other-private)
          Object.keys(result[layer]).forEach(subCategory => {
            const items = result[layer][subCategory];
            const otherItems = items.filter(item => item.toLowerCase().includes('other'));
            result[layer][subCategory] = items.filter(item => !item.toLowerCase().includes('other')).concat(otherItems);
          });
        });
        return result;
      })
    );
  }

  filterMenuDataByFeatureToTurnInto(feature: any): (menuData: any) => any {
    return (menuData: any) => {
      
      if (!feature) {
        return menuData;
      }
  
      const geometryType = feature.geometry.type;
      let categoriesToShow = [];

      if (["Polygon", "MultiPolygon"].includes(geometryType)) {
        categoriesToShow = ['parcel', 'building', 'default', 'guideline_elements']; // 'guideline_elements' disabled for now
      } else if (["Point", "MultiPoint"].includes(geometryType)) {
        categoriesToShow = ['poi', 'junction'];
      } else if (["LineString", "MultiLineString"].includes(geometryType)) {
        categoriesToShow = ['network', 'guideline_elements'];
      }

      const filteredData = {};
      categoriesToShow.forEach(category => {
        if (menuData[category]) {
          filteredData[category] = menuData[category];
        }
      });

      return filteredData;
    };
  }

}
