import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild, Router,
} from '@angular/router';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) {

  }

  canActivate(activatedRoute: ActivatedRouteSnapshot): boolean {
    const projectName = activatedRoute.params.name || 'Dan Park';
    if (this.authService.isAuth() && (this.authService.isAdmin() || this.authService.isPermitted(projectName))) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  canActivateChild(activatedRoute: ActivatedRouteSnapshot): boolean {
    return this.canActivate(activatedRoute);
  }

}
