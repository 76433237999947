import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { concatMap, take } from 'rxjs/operators';
import { SCOPELINE_INDEX_OLD, URBAN_S3_FOLDERS } from 'src/app/config';
import { ImportedLayers } from 'src/app/models/entity-properties';
import { CrudService } from 'src/app/services/crud/crud.service';
import { ProjectStateStoreService } from 'src/app/services/projectStateStore/project-state-store.service';
import { ProjectStoreService } from 'src/app/services/projectStore/project-store.service';
import { StateService } from 'src/app/services/state/state.service';
import { StoresManagerService } from 'src/app/services/storesManagerService/stores-manager.service';
import Utils from 'src/app/utils/utils';

@Component({
  selector: 'app-import-layers-modal',
  templateUrl: './import-layers-modal.component.html',
  styleUrls: ['./import-layers-modal.component.scss']
})
export class ImportLayersModalComponent implements OnInit {
  @Input() selectedLayer: ImportedLayers;
  @Output() response = new EventEmitter<ImportedLayers>();
  @ViewChild('layerInput') layerInput: ElementRef;
  @ViewChild('ledgerInput') ledgerInput: ElementRef;

  options = [
    {label: 'Geojson', key: 'geoJson'},
    {label: 'Vector Tile', key: 'vector-tile'},
    {label: 'Tile', key: 'tile'}
  ];
  file;
  name;
  type;
  url;
  layerFileName
  ledgerFileName;
  ledgerUrl;
  index = -1;
  fetchedLayers: ImportedLayers[] = [];
  isLoadingFetchV2Layers = true;
  layerInfo;

  groupOptions = [];
  // select mode tabs need an array for the ngmodel
  customLayerGroup = [];

  get isValid() {
    if (!this.name || !this.type || !this.url) {
      return false;
    }
    const name = this.name.trim();
    const isValidName = 0 < name.length;
    return isValidName && Utils.isValidUrl(this.url);
  }

  constructor(
    private crudService: CrudService, 
    private stateService: StateService, 
    private projectStateStoreService: ProjectStateStoreService,
    private projectStoreService: ProjectStoreService,
    private storesManagerService: StoresManagerService) { }

  ngOnInit(): void {
    this.fetchV2Layers();
    this.initializeVariables();
  }

  onModelChange(e) {
    console.log(e)
  }

  // initializeVariables() {
  //   if (!this.selectedLayer) {
  //     return;
  //   }
  //   const scopeline = this.stateService.findEntity(SCOPELINE_INDEX_OLD);
  //   this.index = scopeline.properties.layersV2.findIndex(l => l.name === this.selectedLayer.name);
  //   this.name = this.selectedLayer.name;
  //   this.type = this.selectedLayer.type;
  //   this.url = this.selectedLayer.url;
  //   this.layerFileName = this.selectedLayer.layerFileName || '';
  //   this.ledgerFileName = this.selectedLayer.ledgerFileName || '';
  //   this.ledgerUrl = this.selectedLayer.ledgerUrl || '';
  // }

  initializeVariables() {
    // if (!this.selectedLayer) {
    //   return;
    // }
    this.projectStoreService.findEntityById(SCOPELINE_INDEX_OLD).pipe(
      take(1)
    ).subscribe(scopeline => {
      this.groupOptions = [...new Set(scopeline.properties.layersV2
        .filter(layer => layer.customGroupName !== undefined)
        .map(layer => layer.customGroupName))];

      if (!this.selectedLayer) {
        return;
      }
      this.index = scopeline.properties.layersV2.findIndex(l => l.name === this.selectedLayer.name);
      this.name = this.selectedLayer.name;
      if (this.selectedLayer.customGroupName !== undefined) {
        this.customLayerGroup.push(this.selectedLayer.customGroupName);
      }
      this.type = this.selectedLayer.type;
      this.url = this.selectedLayer.url;
      this.layerFileName = this.selectedLayer.layerFileName || '';
      this.ledgerFileName = this.selectedLayer.ledgerFileName || '';
      this.ledgerUrl = this.selectedLayer.ledgerUrl || '';
      this.layerInfo = this.selectedLayer.info || '';
    });
  }

  // handleOk(): void {
  //   const response: ImportedLayers = {url: this.url, isChecked: false, type: this.type, name: this.name.trim()};
  //   if (this.layerFileName) {
  //     response.layerFileName = this.layerFileName;
  //   }
  //   if (this.ledgerUrl) {
  //     response.ledgerUrl = this.ledgerUrl;
  //     response.ledgerFileName = this.ledgerFileName;
  //   }
  //   const scopeline = this.stateService.findEntity(SCOPELINE_INDEX_OLD);
  //   if (-1 < this.index) {
  //     scopeline.properties.layersV2[this.index] = response;
  //   } else {
  //     scopeline.properties.layersV2.push(response);
  //   }
  //   this.stateService.saveEntity(scopeline);
  //   this.response.next();
  // }

  handleOk(): void {
    const response: ImportedLayers = {url: this.url, isChecked: false, type: this.type, name: this.name.trim(), info: this.layerInfo?.trim(), customGroupName: this.customLayerGroup[0]};
    if (this.layerFileName) {
      response.layerFileName = this.layerFileName;
    }
    if (this.ledgerUrl) {
      response.ledgerUrl = this.ledgerUrl;
      response.ledgerFileName = this.ledgerFileName;
    }

    this.projectStoreService.findEntityById(SCOPELINE_INDEX_OLD)
      .pipe(
        take(1),
        concatMap(scopeline => {
          if (-1 < this.index) {
            scopeline.properties.layersV2[this.index] = response;
          } else {
            scopeline.properties.layersV2.push(response);
          }
          return this.storesManagerService.updateEntity(scopeline);
        }),
        take(1),
      )
      .subscribe(() => {
        this.response.next();
      });
  }

  handleCancel(): void {
    this.response.next(undefined);
  }

  fileSelected(fileType: 'layer' | 'ledger') {
    const file = fileType === 'layer' ? this.layerInput.nativeElement.files[0] : this.ledgerInput.nativeElement.files[0];
    let fileTypes = fileType === 'layer' ? ['.geojson', '.json'] : ['.png', '.gif', '.jpeg'];
    const splitName = file.name.split('.');
    const fileExtension = splitName.length > 1 ? '.' + splitName[1] : '';
    if (fileTypes.indexOf(fileExtension) > -1) {
      this.upload(file, fileType);
    }
  }

  async upload(file: File, fileType: 'layer' | 'ledger') {
    const folderName = `${URBAN_S3_FOLDERS.CUSTOMIZED_LAYERS}-project-${this.projectStateStoreService.projectName}`;
    const response = await this.crudService.uploadFile(file, folderName);
    const url = response.url;
    if (fileType === 'layer') {
      this.layerFileName = this.layerInput.nativeElement.files[0].name;
      this.url = url;
    } else {
      this.ledgerFileName = this.ledgerInput.nativeElement.files[0].name;
      this.ledgerUrl = url;
    }
  }

  resetLegend() {
    this.ledgerFileName = '';
    this.ledgerUrl = '';
  }

  async fetchV2Layers() {
    this.fetchedLayers = await this.crudService.fetchLayersV2();
    this.isLoadingFetchV2Layers = false;
  }

  selectFetchedLayer(index: number) {
    this.name = this.fetchedLayers[index].name;
    this.type = this.fetchedLayers[index].type;
    this.url = this.fetchedLayers[index].url;
    this.layerInfo = this.fetchedLayers[index].info;
    this.layerFileName = this.fetchedLayers[index].layerFileName || '';
    this.ledgerFileName = this.fetchedLayers[index].ledgerFileName || '';
      this.ledgerUrl = this.fetchedLayers[index].ledgerUrl || '';
  }

}
