import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzSliderModule} from 'ng-zorro-antd/slider';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzTagModule} from 'ng-zorro-antd/tag';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzProgressModule } from 'ng-zorro-antd/progress';



@NgModule({
  imports: [
    CommonModule,
    NzLayoutModule,
    NzTabsModule,
    NzCollapseModule,
    NzDropDownModule,
    NzButtonModule,
    NzListModule,
    NzDividerModule,
    NzCardModule,
    NzSwitchModule,
    NzInputModule,
    NzSliderModule,
    NzSelectModule,
    NzTreeModule,
    NzSkeletonModule,
    NzUploadModule,
    NzBadgeModule,
    NzFormModule,
    NzGridModule,
    NzRadioModule,
    NzCheckboxModule,
    NzInputNumberModule,
    NzAlertModule,
    NzToolTipModule,
    NzTagModule,
    NzPageHeaderModule,
    NzDescriptionsModule,
    NzSpaceModule,
    NzSpinModule,
    NzModalModule,
    NzAutocompleteModule,
    NzDrawerModule,
    NzStatisticModule,
    NzTypographyModule,
    NzPopoverModule,
    NzBreadCrumbModule,
    NzProgressModule
  ],
  exports: [
    NzLayoutModule,
    NzTabsModule,
    NzCollapseModule,
    NzDropDownModule,
    NzButtonModule,
    NzListModule,
    NzDividerModule,
    NzCardModule,
    NzSwitchModule,
    NzInputModule,
    NzSliderModule,
    NzSelectModule,
    NzTreeModule,
    NzSkeletonModule,
    NzUploadModule,
    NzBadgeModule,
    NzFormModule,
    NzGridModule,
    NzRadioModule,
    NzCheckboxModule,
    NzInputNumberModule,
    NzAlertModule,
    NzToolTipModule,
    NzTagModule,
    NzPageHeaderModule,
    NzDescriptionsModule,
    NzSpaceModule,
    NzSpinModule,
    NzModalModule,
    NzAutocompleteModule,
    NzDrawerModule,
    NzStatisticModule,
    NzTypographyModule,
    NzPopoverModule,
    NzBreadCrumbModule,
    NzProgressModule
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
})
export class ZorroAntdModule { }
