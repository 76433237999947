export enum ENTITY_GEOMETRIC_TYPES {
  POLYGON = 'Polygon',
  CIRCLE = 'Circle',
  POINT = 'Point',
  LINE_STRING = 'LineString',
  MULTI_LINE_STRING = 'MultiLineString',
  MULTI_POLYGON = 'MultiPolygon',
  POLYLINE = 'Polyline'
}

// export enum ENTITY_CATEGORIES {
//   PARCEL = 'parcel',
//   NETWORK = 'network',
//   POI = 'poi',
//   INFLUENCE = 'influence'
// }

export enum ENTITY_CATEGORIES {
  PARCEL = 'parcel',
  BUILDING = 'building',
  NETWORK = 'network',
  POI = 'poi',
  JUNCTION = 'junction',
  GUIDELINE_ELEMENTS = 'guideline_elements',
  DEFAULT = 'default'
}

export enum DROPDOWN_STATES {
  CONTEXT_MENU = 'context_menu',
  TURNINTO_BUTTON = 'turninto_button',
  ELLIPSIS_MENU='ellipsis_menu',
}

export enum ENTITY_ACCESS_TYPE {
  PRIVATE = 'private',
  PUBLIC = 'public',
  OTHER = 'other'
}

export enum PARAMS_WIDGETS {
  SELECTION = 'SELECTION', // maybe instead of CHECKBOX & MULTISELECT ?
  SLIDER = 'SLIDER',
  INPUT_TEXT = 'INPUT_TEXT',
  CHECKBOX = 'CHECKBOX',
  MULTISELECT = 'MULTISELECT',
  DROPDOWN = 'DROPDOWN',
  LABEL = 'LABEL',
  INPUT_NUMBER = 'INPUT_NUMBER',
  RADIO = 'RADIO',
  OTHER = 'OTHER'
}

export enum SUN_HOUR_ANALYSIS_PARAMS {
  WINTER = 'sun_hour_shade_layout_winter',
  SPRING = 'sun_hour_shade_layout_spring',
  SUMMER = 'sun_hour_shade_layout_summer',
  AUTUMN = 'sun_hour_shade_layout_autumn'
}

export enum BASE_MAPS {
  DEFAULT = 'default',
  SATELLITE = 'satellite',
  NONE = 'none'
}

export enum CHECKBOX_MODES {
  ALL = 'all',
  INTERMEDIATE = 'intermediate',
  NONE = 'none'
}

export enum PANEL_TABS {
  Map_Layer,
  Plan_Layer,
  Edit_Layer
}

export enum TABLE_TABS {
  'ALL' = 'All',
  'ALL ENTITIES'= 'All entities',
  'LOTS' = 'Lots',
  'LOTS (GOAL)' = 'Lots (goal)',
  'BUILDINGS' = 'Buildings',
  'COMMENTS' = 'Comments'
}

export enum ProjectRoles {
  ADMIN = 'admin',
  PRJ_EDITOR = 'editor',
  VIEWER = 'viewer'
}
