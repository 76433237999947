import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {ENTITY_PLAN_TYPE, URBAN_S3_FOLDERS, URBAN_S3_URL} from "../../../config";
import Utils from 'src/app/utils/utils';

@Component({
  selector: 'app-param-config-data-table',
  templateUrl: './param-config-data-table.component.html',
  styleUrls: ['./param-config-data-table.component.scss']
})
export class ParamConfigDataTableComponent implements OnInit {

  constructor() { }

  @Input() data : {} = {};
  type= 'Default';
  isLoadingUploadFile = {};
  @Input() subCategory!: string;
  @Output() notifyUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendRefToParent: EventEmitter<any> = new EventEmitter<any>();
  
  ngOnInit(): void {
  }

  getObjKeys(obj) {
    return Object.keys(obj);
  }

 

  getPatternUrl(d) {
    return `${URBAN_S3_URL}/${URBAN_S3_FOLDERS.PATTERNS}/${this.data[d].pattern}`;
  }

  is3D(d) {
    return this.data[d] && this.data[d].pattern && this.data[d].pattern.indexOf('.gltf') > -1;
  }

  getHexColor(color) {
    return Utils.getHexColor(color);
  }

  uploadFileStaging(filesList, l,subCategory = this.subCategory) {
    const funcRef = {func : this.setUploadFile.bind(this)};
    this.isLoadingUploadFile[l] = true;
    this.notifyUpload.emit({filesList, l,subCategory,funcRef});
  }


  deleteParam(menuKey,subCategory, index = null, p = null){
    this.notifyDelete.emit({menuKey,subCategory, index, p});
  }
  

  setUploadFile(l) {
    this.isLoadingUploadFile[l] = false;
  }

}
