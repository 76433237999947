export const MAVAT_DATA = [
  {
    fillColor: '#FFDF7E',
    landUse: 'Residential',
    mavatCode: '10',
    name: {
      en: 'Residential',
      he: 'מגורים'
    },
    usages: 'residential',
    optionalUsages: 'commercial, office, retail, public building'
  },
  {
    fillColor: '#7C007F',
    landUse: 'Retail',
    mavatCode: '210',
    name: {
      en: 'Retail',
      he: 'מסחר'
    },
    usages: 'retail',
    optionalUsages: 'commercial, office, retail, public building'
  },
  {
    fillColor: '#DDA56E',
    landUse: 'Public Building',
    mavatCode: '400',
    name: {
      en: 'Public Building',
      he: 'מבנים ומוסדות ציבור'
    },
    usages: 'public building',
    optionalUsages: 'commercial, office, retail'
  },
  {
    fillColor: '#ADDD00',
    landUse: 'Public Park',
    mavatCode: '670',
    name: {
      en: 'Public Park',
      he: 'שטח ציבורי פתוח'
    },
    usages: 'NA',
    optionalUsages: 'NA'
  },
  {
    fillColor: '',
    landUse: 'Pedestrian zone',
    mavatCode: '780',
    name: {
      en: 'Pedestrian zone',
      he: 'ככר עירונית'
    },
    usages: 'NA',
    optionalUsages: 'NA'
  },
  {
    fillColor: '',
    landUse: 'Authorised Road',
    mavatCode: '820',
    name: {
      en: 'Authorised Road',
      he: 'דרך מאושרת'
    },
    usages: 'NA',
    optionalUsages: 'NA'
  },
  {
    fillColor: '',
    landUse: 'Proposed Road',
    mavatCode: '830',
    name: {
      en: 'Proposed Road',
      he: 'דרך מוצעת'
    },
    usages: 'NA',
    optionalUsages: 'NA'
  },
  {
    fillColor: '',
    landUse: 'Mix Use - Commercial and Public Building',
    mavatCode: '1600',
    name: {
      en: 'Mix Use - Commercial and Public Building',
      he: 'תעסוקה ומבנים ומוסדות ציבור'
    },
    usages: 'commercial, public building',
    optionalUsages: 'office, retail'
  }
]

