import { Component, OnInit } from '@angular/core';
import { LoaderSmallService } from 'src/app/services/loaderSmall/loader-small.service';


@Component({
  selector: 'app-loader-small',
  templateUrl: './loader-small.component.html',
  styleUrls: ['./loader-small.component.scss']
})
export class LoaderSmallComponent implements OnInit {

  constructor(public loaderSmallService: LoaderSmallService) { }

  ngOnInit(): void {
  }

}
