import { Component, OnInit } from '@angular/core';
import { LoaderFullScreenService } from 'src/app/services/loaderFullScreen/loader-full-screen.service';

@Component({
  selector: 'app-loader-fullscreen',
  templateUrl: './loader-fullscreen.component.html',
  styleUrls: ['./loader-fullscreen.component.scss']
})
export class LoaderFullscreenComponent implements OnInit {

  constructor(public loaderFullScreenService: LoaderFullScreenService) { }

  ngOnInit(): void {
  }
}
