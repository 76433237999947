import {Injectable} from '@angular/core';
import {StateService} from '../state/state.service';
import {DASHBOARD_WIDGET_TABS_DEFAULTS, SCOPELINE_INDEX_OLD} from '../../config';
import {PlanInterface} from '../../models/plan-interface';
import {CrudService} from '../crud/crud.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import Utils from '../../utils/utils';
import {AuthService} from '../auth/auth.service';
import {Feature, Geometry} from 'geojson';
import {EntityProperties} from '../../models/entity-properties';
import {ENTITY_GEOMETRIC_TYPES, PANEL_TABS, ProjectRoles} from '../../enums/enums';
import {EntitiesService} from '../entities/entities.service';
import { ProjectStoreService } from '../projectStore/project-store.service';
import { PlansStoreService } from '../plansStore/plans-store.service';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { StoresManagerService } from '../storesManagerService/stores-manager.service';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  // get plans(): any[] {
  //   return Utils.clone(this.stateService.plans$.getValue());
  // }

  constructor(
    private stateService: StateService,
    private projectStoreService: ProjectStoreService,
    private plansStoreService: PlansStoreService,
    private storesManagerService: StoresManagerService,
    private entitiesService: EntitiesService,
    private crudService: CrudService,
    private nzMessageService: NzMessageService,
    private authService: AuthService) {
  }

  // async savePlan(plan: PlanInterface) {
  //   try {
  //     plan.planDate = Date.now();
  //     const blueline = this.stateService.findEntity(SCOPELINE_INDEX_OLD);
  //     if (blueline.properties.plansOrder.indexOf(plan.planName) === -1) {
  //       blueline.properties.plansOrder.push(plan.planName);
  //       await this.stateService.saveEntity(blueline);
  //     }
  //     await this.crudService.upsertPlan(plan);
  //     return true;
  //   } catch (e) {
  //     this.nzMessageService.error(`Something Went Wrong While Saving...`);
  //     return false;
  //   }
  // }

  generateNewPlanO(): Observable<PlanInterface> {
    return combineLatest([this.projectStoreService.projectName$, this.plansStoreService.plans$])
      .pipe(
        take(1),
        map(([projectName, plansList]) => {
          const planName = this.newPlanName(plansList);
          const plan: PlanInterface = {
            planName: planName,
            projectName: projectName,
            planConfig: {'-1': {version: 'v0'}},
            planDate: Date.now(),
            isLock: false,
            agenda: null,
            calculate: false,
            dashboardWidgetsTabs: DASHBOARD_WIDGET_TABS_DEFAULTS
          }
          return plan;
        }),
        catchError(error => {
          console.error(error);
          return throwError(error);
        })
      );
  }
  

  // deprecated 
  // async generateNewPlan(projectName) {
  //   const plan: PlanInterface = {
  //     planName: 'Untitled Plan',
  //     projectName: projectName,
  //     planConfig: {'-1': {version: 'v0'}},
  //     isLock: false,
  //     agenda: null,
  //     calculate: false,
  //     dashboardWidgetsTabs: DASHBOARD_WIDGET_TABS_DEFAULTS
  //   }
  //   await this.addNewPlan(plan);
  // }

  newPlanName(plansList: PlanInterface[]) {
    let planName = 'Untitled Plan'
    let index = plansList.findIndex(p => p.planName === planName);

    while (index > -1) {
      const planNumber = parseInt(planName.replace('Untitled Plan', '').trim());
      const isValidNum = !isNaN(planNumber);
      planName = `Untitled Plan ${isValidNum ? planNumber + 1 : 1}`;
      index = plansList.findIndex(p => p.planName === planName);
    }
    return planName;
  }

  // async addNewPlan(plan: PlanInterface) {
  //   let plans = this.plans;
  //   const index = plans.findIndex(p => p.planName === plan.planName);

  //   if (index > -1) {
  //     if (plan.planName.includes('Untitled Plan')) {
  //       const planNumber = parseInt(plan.planName.replace('Untitled Plan', '').trim());
  //       const isValidNum = !isNaN(planNumber);
  //       plan.planName = `Untitled Plan ${isValidNum ? planNumber + 1 : 1}`;
  //       this.addNewPlan(plan);
  //     } else {
  //       this.nzMessageService.warning(`${plan.planName} already exists`);
  //     }
  //     return;
  //   }
  //   plans.push(plan);
  //   this.stateService.plans$.next(plans);
  //   try {
  //     await this.savePlan(plan);
  //   } catch (e) {
  //     this.nzMessageService.error(`Error while saving!`);
  //   }
  // }

  async duplicatePlan(plan: PlanInterface) {
    // const nPlan = Utils.clone(plan);
    // // let id = this.stateService.generateId;
    // const entitiesToCopy = Utils.clone(plan.planConfig);
    // nPlan.planName = `copy of ${plan.planName}`;
    // if (this.plans.findIndex(plan => plan.planName === nPlan.planName) > -1) {
    //   do {
    //     nPlan.planName = `copy of ${nPlan.planName}`;
    //   }
    //   while (this.plans.findIndex(plan => plan.planName === nPlan.planName) > -1)
    // }
    // nPlan.planConfig = {};
    // nPlan.planConfig[SCOPELINE_INDEX_OLD] = {version: 'v0'};
    // let oldNewIds = {};
    // let entities = [];
    // // const allEntities = this.stateService.allProjectEntities;
    // allEntities.forEach(ent => {
    //   if (ent.id !== SCOPELINE_INDEX_OLD && entitiesToCopy[ent.id]) {
    //     const entity = Utils.clone(ent);
    //     oldNewIds[entity.id] = id;
    //     nPlan.planConfig[id] = plan.planConfig[entity.id]
    //     entity.id = id.toString();
    //     id++;
    //     entities.push(entity);
    //   }
    // });
    // Object.keys(nPlan.planConfig).forEach(key => {
    //   if (nPlan.planConfig[key].parentId) {
    //     nPlan.planConfig[key].parentId = oldNewIds[nPlan.planConfig[key].parentId];
    //   }
    // });
    // this.stateService.sendAllProjectEntities(allEntities.concat(entities));
    // try {
    //   await this.crudService.saveEntitiesConfigsBulk(entities);
    //   await this.addNewPlan(nPlan);
    //   this.nzMessageService.success(`${entities.filter(entity => !entity.properties.hasOwnProperty('heightProperties')).length} entities copied!`);
    // } catch (e) {
    //   this.nzMessageService.error(`Something went wrong while duplicating...`);
    // }
  }

  duplicatePlanO(plan: PlanInterface): Observable<{ newPlan: PlanInterface, entities: Feature<Geometry, EntityProperties>[] }> {
    return this.plansStoreService.plans$.pipe(
      switchMap((plans) => {
        const nPlan = Utils.clone(plan);
        let nId: number = this.entitiesService.generateId();
        let copyNum = 1;
        const entitiesToCopy = Utils.clone(plan.planConfig);
        nPlan.planName = `copy of ${plan.planName}`;
  
        if (plans.findIndex(plan => plan.planName === nPlan.planName) > -1) {
          do {
            nPlan.planName = `copy ${copyNum} of ${plan.planName}`;
            copyNum++
          } while (plans.findIndex(plan => plan.planName === nPlan.planName) > -1)
        }
  
        nPlan.planConfig = {};
        nPlan.planConfig[SCOPELINE_INDEX_OLD] = {version: 'v0'};
  
        // Create an array of entity ids
        const entityIds = Object.keys(entitiesToCopy).filter(id => id !== SCOPELINE_INDEX_OLD.toString());
  
        // Use getEntitiesByIds to get an observable of entities
        return this.projectStoreService.getEntitiesByIds(entityIds).pipe(
          map((allEntities: Feature<Geometry, EntityProperties>[]) => {
            const oldNewIds = {};
            let entities = [];
  
            allEntities.forEach(ent => {
              const entity = Utils.clone(ent);
              oldNewIds[entity.id] = nId;
              nPlan.planConfig[nId.toString()] = {...plan.planConfig[entity.id]};
              entity.id = nId.toString();
              nId++;
              entities.push(entity);
            });
            
            Object.keys(nPlan.planConfig).forEach(key => {
              if (nPlan.planConfig[key].parentId) {
                nPlan.planConfig[key].parentId = oldNewIds[nPlan.planConfig[key].parentId].toString();
              }
            });
  
            return { newPlan: nPlan, entities: entities };
          })
        );
      })
    );
}
  

  // async deletePlan(plan: PlanInterface) {
  //   const plans = this.plans;
  //   if (plans.length === 1) {
  //     return;
  //   }
  //   plans.splice(plans.map(p => p.planName).indexOf(plan.planName), 1);
  //   this.stateService.plans$.next(plans);
  //   await this.crudService.deletePlan(plan.projectName, plan.planName);
  //   const blueline = this.stateService.findEntity(SCOPELINE_INDEX_OLD);
  //   blueline.properties.plansOrder = blueline.properties.plansOrder.filter(p => p !== plan.planName);
  //   this.stateService.updateEntity = blueline;
  //   if (this.stateService.currentPlan$.getValue().planName === plan.planName) {
  //     // this.changePlan(plans[0]);
  //     return;
  //   }
  //   const entities = this.stateService.entities;
  //   const dx = entities.features.findIndex(ent => ent.id === blueline.id);
  //   entities.features[dx] = {...blueline};
  //   this.stateService.updateEntitiesArrayAndObject(entities);
  // }

  // changePlan(plan: PlanInterface) {
  //   this.stateService.changeSelectedPlan(plan);
  //   this.stateService.planBluelineId = undefined;
  //   this.stateService.resetHistory();
  //   const entities = this.stateService.generatePlanEntities(false);
  //   this.stateService.updateEntitiesArrayAndObject(entities);
  //   this.stateService.updateHistoryState({action: 'upsert', entities: entities.features, plan, selected: []});
  //   // FRN
  //   // this.stateService.setSelectedEntities([], false, false);
  //   this.storesManagerService.setSelectedEntities([], false);
  //   this.entitiesService.fetchShadows(this.stateService.projectName, plan.planName);
  //   // this.stateService.panelTab = PANEL_TABS.Plan_Layer;
  //   this.storesManagerService.setPanelTab(PANEL_TABS.Plan_Layer);
  // }

  //original deprecated
  // async rename(newPlanName: string, plan: PlanInterface) {
  //   if (newPlanName === plan.planName || newPlanName.trim() === plan.planName) {
  //     return;
  //   }
  //   newPlanName = newPlanName.trim();
  //   let plans = this.plans;
  //   if (plans.findIndex(p => p.planName === newPlanName) > -1) {
  //     this.nzMessageService.warning(`Name already exists`);
  //   }
  //   const scopeline = this.stateService.findEntity(SCOPELINE_INDEX_OLD);
  //   const planDoesExistIndex = scopeline.properties.plansOrder.findIndex(n => n === newPlanName);
  //   if(planDoesExistIndex > -1) {
  //     scopeline.properties.plansOrder.splice(planDoesExistIndex, 1);
  //   }
  //   const copyOfOriginalScopeLine = Utils.clone(scopeline);
  //   const planDxInPlanOrder = scopeline.properties.plansOrder.findIndex(n => n === plan.planName);
  //   scopeline.properties.plansOrder.splice(planDxInPlanOrder, 1, newPlanName);
  //   const prevPlanName = plan.planName;
  //   const mutatedPlan = Utils.clone(plan);
  //   mutatedPlan.planName = newPlanName;
  //   plans = this.plans;
  //   const planIndex = plans.findIndex(p => p.planName === prevPlanName)
  //   plans[planIndex].planName = newPlanName;
  //   plans.sort((a, b) => scopeline.properties.plansOrder.indexOf(a.planName) - scopeline.properties.plansOrder.indexOf(b.planName));
  //   this.stateService.plans$.next(plans);
  //   this.stateService.updateEntity = scopeline;
  //   // this.stateService.addEntitiesToState([scopeline]);
  //   this.stateService.currentPlan$.next(mutatedPlan);
  //   try {
  //     await this.crudService.renamePlan({plan, newPlanName, scopeline: copyOfOriginalScopeLine});
  //   } catch (e) {
  //     this.nzMessageService.error('Plan rename error');
  //     plans[planIndex].planName = prevPlanName;
  //     plans.sort((a, b) => copyOfOriginalScopeLine.properties.plansOrder.indexOf(a.planName) - copyOfOriginalScopeLine.properties.plansOrder.indexOf(b.planName));
  //     this.stateService.plans$.next(plans);
  //     this.stateService.updateEntity = copyOfOriginalScopeLine;
  //     // this.stateService.addEntitiesToState([copyOfOriginalScopeLine]);
  //     this.stateService.currentPlan$.next(plan);
  //   }

  // }


  // async toggleLockPlan(plan: PlanInterface) {
  //   if (!this.authService.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR]) || this.stateService.isProjectLock) {
  //     return;
  //   }

  //   plan.isLock = !plan.isLock;
  //   if (plan.planName === this.stateService.currentPlan$.getValue().planName) {
  //     this.stateService.currentPlan$.next(plan);
  //   }
  //   await this.savePlan(plan);
  // }

  toggleLockPlan(plan: PlanInterface): PlanInterface | null {
    if (!this.authService.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR])) {
      this.nzMessageService.error('Unauthorized attempt to toggle plan lock state.');
      return null;
    }
    plan.isLock = !plan.isLock;
    return plan
  }

  // modifyCurrentPlanConfig(ids: string[], param, value) {
  //   this.stateService.modifyPlanConfig(ids.map(id => this.stateService.findEntity(id)), param, value);
  // }

  // removeKeyFromPlanConfig(id: string, param) {
  //   const currentPlan = this.stateService.currentPlan$.getValue();
  //   if (currentPlan.planConfig[id] && currentPlan.planConfig[id][param]) {
  //     delete currentPlan.planConfig[id][param];
  //     this.stateService.currentPlan$.next(currentPlan);
  //   }
  // }


  // deprecated moved to entitiesService
  // handlePolygonWithinPolygon(entity: Feature<Geometry, EntityProperties>) {
  //   if (entity.geometry.type !== ENTITY_GEOMETRIC_TYPES.POLYGON || !entity.properties.entityType) {
  //     return;
  //   }
  //   let parentId;
  //   let childrenId;
  //   let isLot = Utils.isLot(entity.properties.entityType);
  //   if (entity.properties.entityType === 'building') {
  //     const lotBuilding = this.entitiesService.buildingPolygonWithinLotByBuilding(entity);
  //     parentId = lotBuilding ? lotBuilding.lot : undefined;
  //   }
  //   if (isLot) {
  //     parentId = this.entitiesService.lotWithinParcel(entity);
  //     childrenId = this.entitiesService.buildingPolygonWithinLotByLot(entity);
  //   }
  //   if (parentId) {
  //     // @ts-ignore
  //     this.modifyCurrentPlanConfig([entity.id], 'parentId', parentId);
  //   } else {
  //     // @ts-ignore
  //     this.removeKeyFromPlanConfig(entity.id, 'parentId');
  //   }
  //   if (isLot) {
  //     this.stateService.addRemovePlanConfigParentId(entity.id, childrenId && childrenId.map(child => child.building) || undefined);
  //   }
  // }

  verifyDashboardWidgetsTabs(plans: PlanInterface[]) {
    plans.forEach(plan => {
      if (!plan.hasOwnProperty('dashboardWidgetsTabs')) {
        const dashboardWidgetsTabs = DASHBOARD_WIDGET_TABS_DEFAULTS;
        Object.assign(plan, {dashboardWidgetsTabs});
      } else {
        plan.dashboardWidgetsTabs.forEach((tab, index) => {
          if (tab.url === ' ') {
            tab.url = DASHBOARD_WIDGET_TABS_DEFAULTS[index].url;
          }
        });
      }
    });
    return plans;
  }

}
