import { Injectable } from '@angular/core';
import { Feature, Geometry, Position } from 'geojson';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BASE_MAPS, PANEL_TABS } from 'src/app/enums/enums';
import { EntityMap } from 'src/app/models/entity-map';
import { EntityProperties } from 'src/app/models/entity-properties';
import { ExternalLayer, IExternalLayersObject } from 'src/app/models/external-layer';
import { PlanInterface } from 'src/app/models/plan-interface';
import { ProjectStoreService } from '../projectStore/project-store.service';
import { BLUELINE_LANDUSE, DEFAULT_MAP_INFO, SCOPELINE_INDEX_OLD } from 'src/app/config';

@Injectable({
  providedIn: 'root'
})
export class ProjectStateStoreService {

  private currentPlanSubject: BehaviorSubject<PlanInterface> = new BehaviorSubject<PlanInterface>(null);
  currentPlan$: Observable<PlanInterface> = this.currentPlanSubject.asObservable();

  private selectedEntitiesSubject: BehaviorSubject<Array<Feature<Geometry, EntityProperties>>> = new BehaviorSubject<Array<Feature<Geometry, EntityProperties>>>([]);
  selectedEntities$: Observable<Array<Feature<Geometry, EntityProperties>>> = this.selectedEntitiesSubject.asObservable();

  // private selectedEntitiesMapSubject: BehaviorSubject<EntityMap> = new BehaviorSubject<EntityMap>({});
  // selectedEntitiesMap$: Observable<EntityMap> = this.selectedEntitiesMapSubject.asObservable();

  private externalLayersSubject: BehaviorSubject<IExternalLayersObject> = new BehaviorSubject<IExternalLayersObject>({
    layers: [],
    add: [],
    remove: []
  });
  externalLayers$: Observable<IExternalLayersObject> = this.externalLayersSubject.asObservable();

  // private hoveredEntitiesIndexesSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  // hoveredEntitiesIndexes$: Observable<string[]> = this.hoveredEntitiesIndexesSubject.asObservable();

  private basemapSubject: BehaviorSubject<BASE_MAPS> = new BehaviorSubject<BASE_MAPS>(BASE_MAPS.DEFAULT);
  basemap$: Observable<BASE_MAPS> = this.basemapSubject.asObservable();

  private panelTabSubject: BehaviorSubject<PANEL_TABS> = new BehaviorSubject<PANEL_TABS>(PANEL_TABS.Map_Layer);
  panelTab$: Observable<PANEL_TABS> = this.panelTabSubject.asObservable();


  // private _showScopeLineSource = new BehaviorSubject<boolean>(false);
  // showScopeLine$ = this._showScopeLineSource.asObservable();

  private dashboardTypeSubject: BehaviorSubject<'plan' | 'selection'> = new BehaviorSubject<'plan' | 'selection'>('plan');
  dashboardType$: Observable<'plan' | 'selection'> = this.dashboardTypeSubject.asObservable();

  private recenterCoordinatesSubject: Subject<Position> = new Subject<Position>();
  recenterCoordinates$: Observable<Position> = this.recenterCoordinatesSubject.asObservable();

  private mapZoomSubject: BehaviorSubject<number> = new BehaviorSubject<number>(DEFAULT_MAP_INFO.zoom);
  mapZoom$: Observable<number> = this.mapZoomSubject.asObservable();

  // FRN  ?change to behaviorSubject?
  // assigned when project is loaded //
  projectName: string;


  constructor(private projectStoreService: ProjectStoreService) { }

  nextCurrentPlan(plan: PlanInterface): void{
    this.currentPlanSubject.next(plan);
  }

  // FRN for debugging only don't use
  currentPlanValue() {
    return this.currentPlanSubject.getValue()
  }

  nextSelectedEntities(entities: Feature<Geometry, EntityProperties>[]){
    this.selectedEntitiesSubject.next(entities);
  }


  addEntitiesToSelectedEntities(entities: Feature<Geometry, EntityProperties>[]): Observable<Feature<Geometry, EntityProperties>[]> {
    return this.selectedEntities$.pipe(
      take(1), // Take the current value of selectedEntities$
      map(currentSelectedEntities => {
        // Create a copy of the current selected entities
        const updatedSelectedEntities = [...currentSelectedEntities];
        // Iterate through the new entities to add
        entities.forEach(entity => {
          // Check if the entity is not already included based on its id
          if (!updatedSelectedEntities.find(e => e.id === entity.id)) {
            // If not found, add the entity to the array
            updatedSelectedEntities.push(entity);
          }
        });
        // Emit the updated selected entities array
        // this.selectedEntitiesSubject.next(updatedSelectedEntities);
        // Return the updated selected entities array as part of the Observable stream
        return updatedSelectedEntities;
      })
    );
  }
  
  removeEntitiesFromSelectedEntities(entitiesToRemove: Feature<Geometry, EntityProperties>[]): void {
    this.selectedEntities$.pipe(
      take(1)
    ).subscribe(currentSelectedEntities => {
      const updatedSelectedEntities = currentSelectedEntities.filter(
        entity => !entitiesToRemove.some(e => e.id === entity.id)
      );
      this.selectedEntitiesSubject.next(updatedSelectedEntities);
    });
  }
  

  nextExternalLayers(externalLayers: IExternalLayersObject) {
    this.externalLayersSubject.next(externalLayers);
  }

  addExternalLayers(newLayers: ExternalLayer[]) {
    const externalLayers = this.externalLayersSubject.getValue();
    externalLayers.remove = [];
    const currentLayers = externalLayers.layers.reduce((prev, curr) => {
      prev[curr.name] = curr.name;
      return prev;
    }, {});
    newLayers.forEach(layer => {
      const isLayerExists = currentLayers[layer.name];
      if (!isLayerExists) {
        externalLayers.layers.push(layer);
        externalLayers.add.push(layer);

      }
    });
    this.nextExternalLayers(externalLayers);
  }

  removeExternalLayers(removeLayers: ExternalLayer[]) {
    const externalLayers = this.externalLayersSubject.getValue();
    let layers = externalLayers.layers;
    const currentLayers = externalLayers.layers.reduce((prev, curr) => {
      prev[curr.name] = curr.name;
      return prev;
    }, {});
    removeLayers.forEach(layer => {
      const removeLyr = currentLayers[layer.name];
      if (removeLyr) {
        layers = layers.filter(lyr => lyr.name !== removeLyr);
      }
    });
    this.nextExternalLayers({ layers, add: [], remove: removeLayers });
  }

  resetExternalLayers() {
    const externalLayers = this.externalLayersSubject.getValue();
    const currentLayers = externalLayers.layers;
    if (currentLayers?.length > 0) {
      this.nextExternalLayers({layers: [], add: [], remove: currentLayers});
    }
  }

  getCurrentPlanEntities(): Observable<EntityMap> {
    return this.currentPlan$.pipe(
      // tap(data => {console.log("CurrentPlan: ", data)}),
      filter(currentPlan => currentPlan !== null),
      switchMap(currentPlan => {
        const entitiesIds = Object.keys(currentPlan.planConfig);
        // console.log("CurrentEntitiesID: ", entitiesIds)
        return this.projectStoreService.projectEntitiesMap$.pipe(
          map(entitiesMap => {
            return entitiesIds.reduce((result, entityId) => {
              const entity = entitiesMap[entityId];
              if (entity) {
                result[entityId] = entity;
              }
              return result;
            }, {} as EntityMap);
          }),
          filter(entityMap => Object.keys(entityMap).length > 0)
          // tap(data => console.log("GCE: ", data))
        );
      })
    );
  }

  isBlueLine(): Observable<boolean> {
    return this.getCurrentPlanEntities().pipe(
      map(entityMap => {
        const entityIds = Object.keys(entityMap);
        for (const entityId of entityIds) {
          const entity = entityMap[entityId];
          if (entity.properties && entity.properties.landUse === BLUELINE_LANDUSE) {
            return true;
          }
        }
        return false;
      })
    );
  }
  

  nextRecenterCoordinates(position: Position) {
    this.recenterCoordinatesSubject.next(position)
  }

  nextDashboardType(dashboardType: 'plan' | 'selection') {
    this.dashboardTypeSubject.next(dashboardType);
  }

  nextBasemap(basemap: BASE_MAPS) {
    this.basemapSubject.next(basemap);
  }

  nextPanelTab(panelTab: PANEL_TABS) {
    this.panelTabSubject.next(panelTab);
  }

  nextZoom(zoom: number) {
    this.mapZoomSubject.next(zoom)
  }

  initMapCenter(options: any): Observable<any> {
    return this.projectStoreService.findEntityById(SCOPELINE_INDEX_OLD).pipe(
      // tap(data => console.log("in INITMap", data)),
      switchMap((entity: Feature<Geometry, EntityProperties>) => {
        const blueline: any = entity ? entity.properties : null;
        try {
          options.center = [blueline.mapInfo.mapCenter[0], blueline.mapInfo.mapCenter[1]];
        } catch (e) {
          options.center = DEFAULT_MAP_INFO.coordinates;
        }
  
        const newMapZoom = blueline && blueline.mapInfo && blueline.mapInfo.zoom || DEFAULT_MAP_INFO.zoom;
        this.mapZoomSubject.next(newMapZoom);
  
        return this.mapZoom$.pipe(
          map((zoom: number) => {
            options.zoom = zoom;
            return options;
          })
        );
      })
    );
  }
  
}
