import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Color, DirectionalLight, PerspectiveCamera, PointLight, Scene, WebGLRenderer} from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

@Component({
  selector: 'app-gltf-viewer',
  templateUrl: './gltf-viewer.component.html',
  styleUrls: ['./gltf-viewer.component.scss']
})
export class GltfViewerComponent implements OnInit, AfterViewInit {
  @ViewChild('container') container: ElementRef;

  @Input() width = 100;
  @Input() height = 100;
  @Input() backgroundColor = 0xffffff;
  @Input() url = 'https://storage.googleapis.com/urban_files_bucket/patterns/1623357409019-scene.gltf';

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
    // this.init();
  }

  ngAfterViewInit(): void {
    this.init();
  }  

  init() {
    let scene = new Scene();
    scene.background = new Color(this.backgroundColor);

    let camera = new PerspectiveCamera(3, this.width / this.height, 10, 10000);
    camera.position.x = 1000;
    camera.position.y = 100;
    camera.position.z = 10;


    let light = new PointLight(0xc4c4c4, 1.5);
    light.position.set(0, 300, 500);
    scene.add(light);
    let light2 = new PointLight(0xc4c4c4, 1.5);
    light2.position.set(500, 100, 0);
    scene.add(light2);
    let light3 = new PointLight(0xc4c4c4, 1.5);
    light3.position.set(0, 100, -500);
    scene.add(light3);
    let light4 = new PointLight(0xc4c4c4, 1.5);
    light4.position.set(-500, 300, 500);
    scene.add(light4);

    let directionalLight = new DirectionalLight(0xffffff);
    directionalLight.position.set(0, -70, 100).normalize();
    scene.add(directionalLight);

    let renderer = new WebGLRenderer();
    renderer.setSize(this.width, this.height);

    // let container = document.getElementById('container');
    // container.appendChild(renderer.domElement);
    let container = this.container.nativeElement;
    container.appendChild(renderer.domElement);

    let controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener('change', function() {
      renderer;
    });
    controls.enableZoom = true;
    // controls.minPolarAngle = 1.1;
    // controls.maxPolarAngle = 1.9;
    controls.update();

    //let texture = new THREE.TextureLoader().load( './model/awp/textures/dragon_lore.jpg' ); // load texture

    let loader = new GLTFLoader();
    loader.load(this.url, (gltf) => {

      let model = gltf.scene;

      /* model.traverse ( ( o ) => { // add texture
        if ( o.isMesh ) {
          o.material.map = texture
        }
      } ); */

      scene.add(model);
      animate();
    });

    let animate = function() {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();
  }

}
