import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HistoryState } from 'src/app/models/state-history.model';
import { StoresManagerService } from '../storesManagerService/stores-manager.service';
import { take } from 'rxjs/operators';
import { AddUpdateDeleteEntitiesParams, EntityEntryMap } from 'src/app/models/entity-map';
import { ProjectStoreService } from '../projectStore/project-store.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  private history: HistoryState[] = [];
  private currentIndex: BehaviorSubject<number> = new BehaviorSubject(-1);
  private storesManagerService: StoresManagerService;

  constructor(private injector: Injector, private projectStoreService: ProjectStoreService) { }

  private getStoresManagerService(): StoresManagerService {
    if (!this.storesManagerService) {
      this.storesManagerService = this.injector.get(StoresManagerService);
    }
    return this.storesManagerService;
  }

  get currentStateIndex(): Observable<number> {
    return this.currentIndex.asObservable();
  }

  undoRedoState: boolean;

  // addAction(state: HistoryState) {
  //   this.undoRedoState = false;
  //   console.log(state)
  //   // Limit history to 10 actions
  //   if (this.history.length === 15) {
  //     this.history.shift();
  //   } else {
  //     this.currentIndex.next(this.currentIndex.value + 1);
  //   }
  //   this.history.push(state);
  //   console.log(this.history)
  // }

  addAction(state: HistoryState) {
    this.undoRedoState = false;
    // console.log(state);
  
    // If a new action is added after undoing (i.e., currentIndex is not at the last position),
    // remove all future states from the history
    if (this.currentIndex.value < this.history.length - 1) {
      this.history.splice(this.currentIndex.value + 1);
    }
  
    // Limit history to 15 actions
    if (this.history.length >= 15) {
      this.history.shift(); // Remove the oldest action if at limit
      // No need to adjust currentIndex because we are removing the oldest action,
      // but we need to ensure currentIndex reflects the correct position after shifting.
      this.currentIndex.next(this.history.length - 1);
    } else {
      // Increment current index since we're adding a new action and not at the limit.
      this.currentIndex.next(this.currentIndex.value + 1);
    }
  
    this.history.push(state);
    // console.log(this.history);
  }

  undo() {
    this.undoRedoState = true;
    console.log("undo")
    const index = this.currentIndex.value;
    console.log(index)
    if (index > -1) {
      this.applyState(this.history[index], true);
      this.currentIndex.next(index - 1);
    }
  }

  redo() {
    this.undoRedoState = true;
    console.log("Redo")
    const index = this.currentIndex.value + 1;
    console.log(index)
    if (index < this.history.length) {
      this.applyState(this.history[index], false);
      this.currentIndex.next(index);
    }
  }

  private applyState(state: HistoryState, isUndo: boolean) {
    const storesManager = this.getStoresManagerService();
    // Apply changes based on action type. This example handles an 'add' action.
    switch (state.action) {
      case 'add':
        if (isUndo) {
          // Remove added entities
          if (state.entities?.newState?.length > 0) {
            console.log("delete: ", state.entities.newState)
            storesManager.deleteEntitiesFromProjectAndPlanConfig(state.entities.newState, true).pipe(
              take(1)
            ).subscribe();
          }
          // undo plan and plan with entities
          if (state.plan?.newState) {
            storesManager.deletePlanAndPlanEntities(state.plan.newState, true).pipe(
              take(1)
            ).subscribe()
          }
        } else {
          // redo plan with entities
          if (state.entities?.newState?.length > 0 && state.plan?.newState) {
            storesManager.addPlanWithEntities(of({newPlan: state.plan.newState, entities: state.entities.newState}), true).pipe(
              take(1)
            ).subscribe()
          }
          // Re-add entities
          if (state.entities?.newState?.length > 0) {
            console.log("Add: ", state.entities.newState)
            storesManager.addEntitiesToProjectAndPlan(state.entities.newState, state.entities.parentId, true).pipe(
              take(1)
            ).subscribe();
          } 
          // redo plan
          if (state.plan?.newState) {
            storesManager.addNewPlanToPlansNScope(state.plan.newState, true)
          }        
        }
        break;
      case 'delete':
        if (isUndo) {
          if (state.plan?.prevState && state.entities?.prevState) {
            storesManager.addPlanWithEntities(of({newPlan: state.plan.prevState, entities: state.entities.prevState}), true).pipe(
              take(1)
            ).subscribe()
          }
          // Remove added entities
          if (state.entities?.prevState?.length > 0 && !state.plan?.prevState) {
            console.log("to add: ", state.entities.prevState)
            storesManager.addEntitiesToProjectAndPlan(state.entities.prevState, state.entities.parentId , true).pipe(
              take(1)
            ).subscribe();
          }

        } else {
          if (state.plan?.prevState && state.entities?.prevState) {
            storesManager.deletePlanAndPlanEntities(state.plan.prevState, true).pipe(
              take(1)
            ).subscribe()
          }
          if (state.entities?.prevState?.length > 0 && !state.plan?.prevState) {
            console.log("delete again: ", state.entities.prevState)
            storesManager.deleteEntitiesFromProjectAndPlanConfig(state.entities.prevState, true).pipe(
              take(1)
            ).subscribe();
          }
      
        }
        break;
      case 'update':
        if (isUndo) {
          if (state.entities?.prevState?.length === 1) {
            storesManager.updateEntity(state.entities.prevState[0], undefined, true).pipe(
              take(1)
            ).subscribe()
          }
          if (state.plan?.prevState) {
            storesManager.updatePlanInStores(state.plan.prevState, true).pipe(
              take(1)
            ).subscribe()
          }


        } else {
          if (state.entities?.newState?.length === 1) {
            storesManager.updateEntity(state.entities.newState[0], state.entities.parentChildRelationship, true).pipe(
              take(1)
            ).subscribe()
          }
          if (state.plan?.newState) {
            storesManager.updatePlanInStores(state.plan.newState, true).pipe(
              take(1)
            ).subscribe()
          }
      
        }
        break;
      case 'rename':
        if (isUndo) {
          if (state.plan?.prevState) {
            storesManager.renamePlan(state.plan.prevState.planName, state.plan.newState, true).pipe(
              take(1)
            ).subscribe()
          }

        } else {
          if (state.plan?.newState) {
            storesManager.renamePlan(state.plan.newState.planName, state.plan.prevState, true).pipe(
              take(1)
            ).subscribe()
          }
     
        }
        break;
      case 'modify':
        if (isUndo) {
          if (state.modified?.entitiesNewState) {
            const inverseState = this.getInverseModifyState(state.modified);
            if (inverseState) {
              storesManager.addUpdateDeleteEntities(inverseState, state.modified.planName, true).pipe(
                take(1)
              ).subscribe();
            }
          }

        } else {
          if (state.modified?.entitiesNewState) {
            storesManager.addUpdateDeleteEntities(state.modified.entitiesNewState, state.modified.planName, true).pipe(
              take(1)
            ).subscribe()
          }
      
        }
        break;
      case 'changePlan':
        if (isUndo) {
          if (state.plan?.prevState) {
            storesManager.changeCurrentPlan(state.plan.prevState, false);
            if (state.selectedEntitiesID?.newState?.length > 0) {
              this.projectStoreService.getEntitiesByIds(state.selectedEntitiesID.newState).pipe(
                take(1)
              ).subscribe(entities => {
                storesManager.setSelectedEntities(entities, false, false)
              });
            }

          }

        } else {
          if (state.plan?.newState) {
            storesManager.changeCurrentPlan(state.plan.newState, false)
          }
      
        }
        break;
      case 'select':
        if (isUndo) {
          if (state.selectedEntitiesID?.prevState) {
            this.projectStoreService.getEntitiesByIds(state.selectedEntitiesID.prevState).pipe(
              take(1)
            ).subscribe(entities => {
              storesManager.setSelectedEntities(entities, false, false)
            });
          }
        } else {
          if (state.selectedEntitiesID?.newState) {
            this.projectStoreService.getEntitiesByIds(state.selectedEntitiesID.newState).pipe(
              take(1)
            ).subscribe(entities => {
              storesManager.setSelectedEntities(entities, false, false)
            });
          }
      
        }
        break;
      case 'dashboardChange':
        if (isUndo) {
          if (state.dashboardType?.prevState) {
            storesManager.setCurrentDashboardType(state.dashboardType?.prevState, false)
          }
        } else {
          if (state.dashboardType?.newState) {
            storesManager.setCurrentDashboardType(state.dashboardType?.newState, false)
          }     
        }
        break;
      case 'baseMapChange':
        if (isUndo) {
          if (state.baseMap?.prevState) {
            storesManager.setBasemap(state.baseMap?.prevState, false)
          }
        } else {
          if (state.baseMap?.newState) {
            storesManager.setBasemap(state.baseMap?.newState, false)
          }     
        }
        break;
      case 'panelTabChange':
        if (isUndo) {
          if (state.panelTab?.prevState) {
            if (state.selectedEntitiesID.prevState.length > 0) {
              this.projectStoreService.getEntitiesByIds(state.selectedEntitiesID.prevState).pipe(
                take(1)
              ).subscribe(entities => {
                storesManager.setSelectedEntities(entities, false, false)
              });
            }
            storesManager.setPanelTab(state.panelTab.prevState, false)
          }
        } else {
          if (state.panelTab?.newState) {
            storesManager.setPanelTab(state.panelTab?.newState, false)
          }     
        }
        break;

    }

  }

  resetHistory() {
    this.history = [];
    this.currentIndex.next(-1);
  }

  private getInverseModifyState(modifiedState: {
      entitiesNewState?: AddUpdateDeleteEntitiesParams;
      entitiesUpdatedPrevState?: EntityEntryMap;
      entitiesDeletedPrevState?: EntityEntryMap;
      planName?: string;
    }): AddUpdateDeleteEntitiesParams {
    const inverseState: AddUpdateDeleteEntitiesParams = {
      entitiesToAdd: {},
      entitiesToUpdate: {},
      entitiesToDelete: []
    };

    // Inverting additions to deletions
    if (modifiedState.entitiesNewState.entitiesToAdd) {
      Object.keys(modifiedState.entitiesNewState.entitiesToAdd).forEach(key => {
        inverseState.entitiesToDelete.push(key);
      });
    }

    // Reverting updates to their previous state
    if (modifiedState.entitiesNewState.entitiesToUpdate) {
      inverseState.entitiesToUpdate = modifiedState.entitiesUpdatedPrevState;
    }

    // Converting deletions back to additions
    if (modifiedState.entitiesNewState.entitiesToDelete && modifiedState.entitiesNewState.entitiesToDelete.length > 0) {
      inverseState.entitiesToAdd = modifiedState.entitiesDeletedPrevState;
    }

    return inverseState;
  }
}

