import {AfterContentChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-drop-down-multiple',
  templateUrl: './drop-down-multiple.component.html',
  styleUrls: ['./drop-down-multiple.component.scss']
})
export class DropDownMultipleComponent implements OnInit {

  @Input() itemKey: string;
  @Input() set options(val) {
    if(typeof(val) == 'string') {
      this.optionsInstance = val.split(',')
    }
    if(typeof(val) == 'object' && val.length) {
      this.optionsInstance = val;
    }
  }
  @Input() set value(val) {
    if(typeof(val) == 'string') {
      val = val.split(',')
    }
    if(typeof(val) == 'object' && val.length) {
      this.valueControl.setValue(val);
    }
  };
  @Input() title: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  valueControl = new FormControl();
  optionsInstance: string[] = [];

  change(event) {
    this.onChange.emit({value: event});
  }

  constructor() {
  }
  ngOnInit() {
  }

}
