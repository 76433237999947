<nz-modal [nzVisible]="true" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle style="font-size: 16px;">Layer Settings</ng-template>

  <ng-template #modalContent>
    <div>
      <input type="text" placeholder="Layer name" [(ngModel)]="name">

      <nz-select 
        nzAllowClear
        nzMode="tags" 
        nzPlaceHolder="Custom Layer Group" 
        [(ngModel)]="customLayerGroup"
        (ngModelChange)="onModelChange($event)"
        [nzMaxMultipleCount]="1"
        [nzMaxTagCount]="1">
        <nz-option *ngFor="let option of groupOptions" [nzValue]="option" [nzLabel]="option"></nz-option>
      </nz-select>

    </div>
    
 
    <div class="upload-section">
      <div class="upload-button-wrapper high-opacity" *ngIf="type === 'geoJson'" (click)="layerInput.click()">
        <i nz-icon nzType="upload" nzTheme="outline"></i>
        <span>Upload File</span>
        <input #layerInput type="file" accept=".geojson, .json" style="visibility: hidden;"
          (change)="fileSelected('layer')">
      </div>
      <input type="text" placeholder="Layer URL" [(ngModel)]="url">
    </div>
    <div>
      <span style="margin-right: 6px;">Or</span>
      <a nz-dropdown [nzDropdownMenu]="layersMenu" nzTrigger="click" [nzDisabled]="isLoadingFetchV2Layers">copy from existing layers <i style="font-size: 10px;" nz-icon
          nzType="down" nzTheme="outline"></i></a>
    </div>
    <nz-divider></nz-divider>

    <div class="high-opacity" style="margin-bottom: 10px;">File type</div>
    <nz-radio-group [(ngModel)]="type" fxLayout="column">
      <label *ngFor="let option of options; let i = index;" [style.margin-bottom]="i < options.length - 1 ? '14px' : 0"
        nz-radio [nzValue]="option.key">{{option.label}}</label>
    </nz-radio-group>
    <nz-divider></nz-divider>
    <div class="upload-info-section">
      <div>
        <div class="high-opacity">Legend</div>
        <div class="upload-button-wrapper high-opacity" (click)="ledgerInput.click()" *ngIf="!!!ledgerFileName">
          <i nz-icon nzType="upload" nzTheme="outline"></i>
          <span>Upload File</span>
          <input #ledgerInput type="file" accept="image/png, image/gif, image/jpeg" style="visibility: hidden;"
            (change)="fileSelected('ledger')">
        </div>
        <div class="ledger-file-name-container high-opacity" *ngIf="!!ledgerFileName">
          <span>
            <i class="paper-clip" nz-icon nzType="paper-clip" nzTheme="outline"></i>
            <span>{{ledgerFileName}}</span>
          </span>
          <i class="close selectable-cursor" nz-icon nzType="close" nzTheme="outline" (click)="resetLegend()"></i>
        </div>
      </div>
      <div>
        <div class="high-opacity">Add Layer Info</div>
          <textarea 
            nz-input
            [(ngModel)]="layerInfo"
            placeholder="Source, Last Update, Link ..."></textarea>
      </div>
    </div>
    
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" data-cy="cancel-upload-button" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" data-cy="upload-button" [disabled]="!isValid"
      (click)="handleOk()">Confirm</button>
  </ng-template>
</nz-modal>

<nz-dropdown-menu #layersMenu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let layer of fetchedLayers; let i = index" (click)="selectFetchedLayer(i)">{{layer.name}}</li>
  </ul>
</nz-dropdown-menu>