// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_BASE_URL: 'https://backend.urbanize-test.com/v1', // http://localhost:8080/v1
  URBAN_PAZ_EXCEL_SERVER_URL: 'https://paz-server.urbanize-test.com/api',
  AUTH_BASE_URL: 'https://auth.urbanize-test.com',
  WS_URL: 'wss://ws.urbanize.ai',
  BUILDING_PIPELINE_API: 'https://building-pipe.urbanize-test.com/api/',
  URBAN_UTILS_URL: 'https://utils.urbanize-test.com/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
