import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import Utils from '../../utils/utils';
import { environment } from '../../../environments/environment';
import { CrudService } from '../crud/crud.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { tableColumnState, tableGroupState } from "../../components/main-page/panels/panel-table/table/table-consts";
import {HttpHandler, HttpRequest} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import { ProjectRoles } from 'src/app/enums/enums';
import { StateService } from '../state/state.service';
import { ProjectStateStoreService } from '../projectStateStore/project-state-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private permissionsKey = 'permissionsKey';
  private interval;

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private crudService: CrudService,
    private $gaService: GoogleAnalyticsService,
    private modal: NzModalService,
    private stateService: StateService,
    private projectStateStoreService: ProjectStateStoreService
  ) { }

  getUsername() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    // @ts-ignore
    return claims.given_name;
  }

  get projectAccessList() {
    return Utils.getFromStorage(this.permissionsKey) && Utils.getFromStorage(this.permissionsKey).roles;
  }

  isAuth() {
    return this.oauthService.hasValidAccessToken();
  }

  isAdmin() {
    return Utils.getFromStorage(this.permissionsKey).isAdmin;
  }

  isPermitted(projectName: string): boolean {
    if (!this.projectAccessList) {
      return false;
    }
    if (this.projectAccessList.hasOwnProperty('*') || this.projectAccessList.hasOwnProperty('**')) {
      return true;
    }
    return this.projectAccessList.hasOwnProperty(projectName);
  }

  getUserProjectRole(projectName: string): ProjectRoles{
    if (this.isPermitted(projectName)) {
      return Utils.getFromStorage(this.permissionsKey).roles[projectName];
    } else {
      return undefined;
    }
  }

  isAuthorised(roles: ProjectRoles[]): boolean {
    if (this.isAdmin()) return true;
    if (this.isPermitted(this.projectStateStoreService.projectName)) {
      const userProjectRole = Utils.getFromStorage(this.permissionsKey).roles[this.projectStateStoreService.projectName];
      return roles.includes(userProjectRole);
    } else {
      return false;
    }
  }

  async initIsAdminOrEditor() {
    try {
      const res = await this.crudService.getPermissionToUser();
      const userMetaData = res.find(user => user.username === this.getUsername());
      const isAdmin = userMetaData.isEditor;
      const roles = isAdmin ? { '*': '' } : userMetaData.roles;
      Utils.setInStorage(this.permissionsKey, { isAdmin, roles: roles });
    } catch (e) {
      Utils.setInStorage(this.permissionsKey, []);
      console.error(e);
    }
    Utils.setInStorage(tableGroupState, null);
    Utils.setInStorage(tableColumnState, null);
    const expirationTime = this.oauthService.getAccessTokenExpiration();
    Utils.setInSessionStorage('token_expiration_time', { tokenExpirationTime: expirationTime });
    this.interval = setInterval(() => this.setSessionLogoutInterval(), 1000)
  }

  setSessionLogoutInterval() {

    const tokenExpirationTime = Utils.getFromSessionStorage('token_expiration_time');
    const currentTime = new Date().getTime();

    if (tokenExpirationTime && tokenExpirationTime.tokenExpirationTime &&  currentTime > tokenExpirationTime) {
      return this.sessionTimeoutModal();
    }

  }
  
  signOut() {
    this.logoutOauth();
    this.router.navigate(['/']);
  }

  sessionTimeoutModal() {
    if (1 <= this.modal.openModals.length) {
      return;
    }
    const logout = () => {
      this.logoutOauth();
      this.router.navigate(['/']);
    }
    this.modal.info({
      nzTitle: 'Session Timeout',
      nzContent: 'Your session has timed out. Please login again.',
      nzOnOk: () => logout(),
      nzOnCancel: () => logout()
    });
  }

  async fetchTokenUsingPasswordFlowAndLoadUserProfile(username: string, password: string) {
    return await this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(username, password);
  }

  async loginOauth() {
    const authConfig: AuthConfig = {
      issuer: environment.AUTH_BASE_URL,
      redirectUri: window.location.origin + '/#/',
      clientId: 'spa',
      scope: 'openid profile email offline_access api_asd',
      showDebugInformation: true,
      sessionChecksEnabled: true,
      timeoutFactor: 0.01,
      clearHashAfterLogin: false,
      useSilentRefresh: true,
      silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
      oidc: false
    };

    this.oauthService.configure(authConfig);
    await this.oauthService.loadDiscoveryDocument();
  }

  async logoutOauth() {
    Utils.setInStorage(this.permissionsKey, []);
    Utils.removeItemFromSessionStorage('token_expiration_time');
    clearInterval(this.interval);
    this.$gaService.event('logout', 'auth', this.getUsername());
    await this.oauthService.logOut();
  }

}
