import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appNumberInputPrefix]'
})
export class NumberInputPrefixDirective implements OnInit, AfterViewInit {
  @Input() numberInputPrefix: string = '';
  private prefixElement: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.numberInputPrefix && this.prefixElement) {
      this.renderer.setProperty(this.prefixElement, 'innerText', changes.numberInputPrefix.currentValue);
    }
  }

  ngOnInit(): void {
    if (this.numberInputPrefix) {
      this.el.nativeElement.style.position = 'relative';
      this.el.nativeElement.classList.add('has-prefix');
      this.createPrefix();
    }
    
  }

  ngAfterViewInit() {

  }

  createPrefix() {
    // const prefixElement: HTMLElement = this.renderer.createElement('span');
    this.prefixElement = this.renderer.createElement('span');
    this.renderer.setProperty(this.prefixElement, 'innerText', this.numberInputPrefix);
    this.renderer.addClass(this.prefixElement, 'prefix-wrapper');
    if (this.numberInputPrefix.includes('\n')){
      this.el.nativeElement.classList.add('two-lines-prefix');
    }
    this.renderer.appendChild(this.el.nativeElement, this.prefixElement);
  }


}
