import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OAuthModule} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import {NZ_CONFIG, NzConfig} from 'ng-zorro-antd/core/config';
import {NzMessageModule} from 'ng-zorro-antd/message';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {NgProgressModule} from 'ngx-progressbar';
import {NzModalModule, NzModalService} from 'ng-zorro-antd/modal';
import { InputModalComponent } from './shared/components/input-modal/input-modal.component';
import {NzButtonModule} from "ng-zorro-antd/button";
import {SharedModule} from "./modules/shared.module";
import { ParamConfigComponent } from './components/param-config/param-config.component';
import {ParamConfigModule} from "./components/param-config/param-config.module";
import { ImportLayersModalComponent } from './shared/components/import-layers-modal/import-layers-modal.component';

import {NzTypographyModule} from "ng-zorro-antd/typography";



const ngZorroConfig: NzConfig = {
  message: { nzTop: 40, nzDuration: 8000, nzMaxStack: 1 }
};

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    InputModalComponent,
    ParamConfigComponent,
    ImportLayersModalComponent
    ],
  imports: [
    BrowserModule,
    NzMessageModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.URBAN_PAZ_EXCEL_SERVER_URL, environment.API_BASE_URL],
        sendAccessToken: true
      }
    }),
    NgProgressModule.withConfig({
      color: '#0000'
    }),
    NgProgressHttpModule,
    NzModalModule,
    NzButtonModule,
    SharedModule,
    ParamConfigModule,
    NzTypographyModule
  ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: NZ_CONFIG, useValue: ngZorroConfig},
        NzModalService
    ],
  exports: [
  ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
