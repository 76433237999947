<nz-table [nzScroll]="{ x: '1000px', y: '700px' }">
    <div nz-row [nzGutter]="[16, 10]">
    <div style="width: 25%;padding:10px;" *ngFor="let l of getObjKeys(data) ; let i = index;">
      <div class="inner-box param-card">
        <div style="border-bottom: 1px solid rgba(0,0,0,0.1); padding:14px">
          <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-around; width:70%; margin:auto;">
            <input nz-input [id]="l+'-displayName'" autocomplete="off" [(ngModel)]="data[l].displayName">
            <button nz-button [nz-tooltip]="'Delete Parameter'" (click)="deleteParam(l,this.subCategory)" [id]="l+'-delete-param'">
              <i nz-icon nzType="delete"></i>
            </button>
          </div>
        </div>
        <div class="" style="padding:23px" >
          <div>
            <div style="text-align: center; display: grid; grid-template-columns: 10fr">
              <div style="display:flex;margin-left: 10px;">
                <div >
                  <i nz-icon nzType="bg-colors" nzTheme="outline" style="margin-left: -5px;font-size: 17px;"></i>
                </div>
                <div style="margin-left: 10px; margin-top: -9px" [nz-tooltip]="'Fill Color'">
                  <input
                    style="width: 60px;"
                     [id]="l+'-fill-color'"
                     [(colorPicker)]="data[l].color"
                     [value]="getHexColor(data[l].color) || ''"
                     [cpPosition]="'top'"
                     [cpOutputFormat]="'hex'"
                     [style.background]="getHexColor(data[l].color)"/>
                </div>
                <div style="margin-left: 30px;">
                  <label [nz-tooltip]="'Code'"> {{l}}</label>
                </div>
              </div>

              <nz-row style="margin-top: 10px; margin-left: -10px;">
                <nz-col nzSpan="3">
                  <i  [nz-tooltip]="'Thickness'" nz-icon nzType="highlight" nzTheme="outline" style="font-size: 17px;"></i>
                </nz-col>
                <nz-col nzSpan="8">
                  <div>
                    <nz-slider style="background-color: #4f4cd1 !important; width: 20px" [nzStep]="1" [nzMin]="0" [nzMax]="8" [id]="l+'-thickness-slider'"
                                [(ngModel)]="data[l].thickness"></nz-slider>
                  </div>
                </nz-col>
                <nz-col nzSpan="3">
                  <i [nz-tooltip]="'Line Dash'" nz-icon nzType="dash" nzTheme="outline" style="margin-left: -5px;font-size: 17px;"></i>
                </nz-col>
                <nz-col nzSpan="8">
                    <div>
                      <nz-slider class="slider-input" [nzStep]="1" [nzMin]="0" [nzMax]="4" [id]="l+'-dash-slider'"
                                 [(ngModel)]="data[l].lineDash"></nz-slider>
                    </div>
                </nz-col>
              </nz-row>

              <nz-row style="margin-left: -10px;">
                <nz-col nzSpan="3">
                  <i [nz-tooltip]="'Max Zoom'" nz-icon nzType="zoom-in" nzTheme="outline" style="font-size: 17px;"></i>
                </nz-col>
                <nz-col nzSpan="8">
                  <div>
                    <nz-slider style="width: 100px !important;" [nzStep]="1" [nzMin]="1" [nzMax]="22"
                                [id]="l+'-zoom-out-slider'" [(ngModel)]="data[l].maxZoom"></nz-slider>
                  </div>
                </nz-col>
                <nz-col nzSpan="3">
                  <i  [nz-tooltip]="'Min Zoom'" nz-icon nzType="zoom-out" nzTheme="outline" style="font-size: 17px;"></i>
                </nz-col>
                <nz-col nzSpan="8">
                  <div>
                    <nz-slider style="width: 100px !important;" [nzStep]="1" [nzMin]="1" [nzMax]="22"
                                [id]="l+'-zoom-in-slider'" [(ngModel)]="data[l].minZoom"></nz-slider>
                  </div>
                  </nz-col>
              </nz-row>

              <nz-row style="margin-left: -10px;">
                <nz-col nzSpan="3">
                  <i [nz-tooltip]="'Opacity'" nz-icon nzType="bulb" nzTheme="outline" style="font-size: 17px;"></i>
                </nz-col>
                <nz-col nzSpan="8">
                  <div>
                    <nz-slider style="width: 100px !important;" [nzStep]="0.05" [nzMin]="0.1" [nzMax]="1"
                               [id]="l+'-opacity-slider'" [(ngModel)]="data[l].opacity"></nz-slider>
                  </div>
                </nz-col>
                <nz-col nzSpan="3">
                  <i [nz-tooltip]="'Lazy Loading'" nz-icon nzType="hourglass" nzTheme="outline" style="font-size: 17px; margin-top: 5px"></i>
                </nz-col>
                <nz-col nzSpan="8">
                  <div>
                    <nz-switch nzSize="small" style="width: 100px !important; margin-left: -45px"
                               [id]="l+'-lazy-loading'" [(ngModel)]="data[l].lazyLoading"></nz-switch>
                  </div>
                </nz-col>
              </nz-row>

              <nz-row style="margin-left: -10px;">
                <nz-col nzSpan="6" style="margin-left: 10px">
                  <input [nzTooltipTitle]="'z-index'" [id]="l+'-zIndex'" nz-input nz-tooltip autocomplete="off" [placeholder]="'zIndex'"
                         [(ngModel)]="data[l].zIndex">
                </nz-col>
                <nz-col nzSpan="3">
                  <i [nz-tooltip]="'Stroke Color'" nz-icon nzType="border-outer" nzTheme="outline" style="font-size: 17px; margin-left: 52px; margin-top: 10px"></i>
                </nz-col>
                <div style="margin-left: 35px">
                  <input [(colorPicker)]="data[l].strokeColor" [value]="getHexColor(data[l].strokeColor) || ''"
                         [id]="l+'-stroke-color'"
                         [cpPosition]="'top'"
                         [cpOutputFormat]="'hex'"
                         style="width: 60px;"
                         [style.background]="getHexColor(data[l].strokeColor)"/>
                </div>
              </nz-row>

              <nz-row style="margin-top: 10px">
                <nz-col nzSpan="2">
                  <img src="{{getPatternUrl(l)}}" alt="" style="width: 20px; height: 20px; border-radius: 6px" *ngIf="!isLoadingUploadFile[l] && !is3D(l)"/>
                </nz-col>
                <nz-col nzSpan="8">
                  <div class="avatar-edit" [nz-tooltip]="'Edit Pattern'">
                    <input type="file" [id]="'upload_file' + l"
                           (change)="uploadFileStaging($event.target, l)" accept=".jpg, .png, .gltf"/>
                    <label [for]="'upload_file' + l" *ngIf="!isLoadingUploadFile[l]">
                    </label>
                    <nz-spin [nzSize]="'small'" *ngIf="isLoadingUploadFile[l]" style="margin-right: 10px">
                    </nz-spin>
                  </div>
                  <div class="avatar-preview">
                    <div *ngIf="!this.isLoadingUploadFile[l] && this.is3D(l)">
                      <app-gltf-viewer [width]="50" [height]="50" [url]="getPatternUrl(l)"
                                       style="border-radius: 100px"></app-gltf-viewer>
                    </div>
                  </div>
                </nz-col>
              </nz-row>
              <nz-row style="margin-top: 10px">
                <nz-col nzSpan="2">
                  <div style="margin-left: 5px;  width: 80px !important;" *ngIf="type == 'parcel'">
                    <input nz-input autocomplete="off" [placeholder]="'Mavat Code'"
                           [(ngModel)]="data[l].mavatCode">
                  </div>
                  
                </nz-col>
              </nz-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </nz-table>
 