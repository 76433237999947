<nz-modal
  [nzVisible]="true"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
>
  <ng-template #modalTitle style="font-size: 16px;">{{title}}</ng-template>

  <ng-template #modalContent>
    <input nz-input style="text-align: right" autocomplete="off" [(ngModel)]="value">
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" data-cy="cancel-upload-button" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" data-cy="upload-button" [disabled]="!value" (click)="handleOk()">OK</button>
  </ng-template>
</nz-modal>
