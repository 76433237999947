<nz-card class="card-container" *ngIf="title" [nzExtra]="cardHeader">
  <div style=" text-align: center">
    <nz-form-control>
      <label>{{title}}</label>
      <nz-select [id]="itemKey" (ngModelChange)="change($event)" [formControl]="valueControl" nzMode="multiple">
        <nz-option *ngFor="let option of optionsInstance" [id]="option" [nzValue]="option">{{option}}</nz-option>
      </nz-select>
    </nz-form-control>
  </div>
  <ng-template #cardHeader>
    <div class="title" style="display: flex; flex-direction: row; align-items: center; justify-content: space-around;">
      {{title}}
    </div>
  </ng-template>
</nz-card>

<div *ngIf="!title">
  <nz-select (ngModelChange)="change($event)" [formControl]="valueControl" nzMode="multiple" [id]="itemKey">
    <nz-option *ngFor="let option of optionsInstance" [id]="option" [nzLabel]="option" [nzValue]="option">{{option}}</nz-option>
  </nz-select>
</div>
