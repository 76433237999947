import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  PlusOutline,
  EditOutline,
  EyeOutline,
  MoreOutline,
  DownOutline,
  LineChartOutline,
  // InsertRowLeftOutline,
  MenuUnfoldOutline,
  MenuFoldOutline,
  InsertRowBelowOutline,
  LockOutline,
  ForwardOutline,
  ExpandAltOutline,
  CloseOutline,
  BlockOutline,
  RedoOutline,
  UndoOutline,
  DownloadOutline,
  // AreaChartOutline,
  LayoutOutline,
  PlaySquareOutline,
  PlusSquareOutline,
} from '@ant-design/icons-angular/icons';

// TODO: List all of our NZ icons here that we're using *during initial load*. Any NZ icon 
// not listed here will be requested via HTTP in a separate request instead of being bundled
// into the module, and therefore has a slight loading delay before it appears, but in 
// exchange will give us a smaller initial bundle size.
// See https://ng.ant.design/components/icon/en#static-loading-and-dynamic-loading
const icons = [
  PlusOutline,
  EditOutline,
  EyeOutline,
  MoreOutline,
  DownOutline,
  LineChartOutline,
  // AreaChartOutline,
  // InsertRowLeftOutline,
  MenuUnfoldOutline,
  MenuFoldOutline,
  InsertRowBelowOutline,
  LayoutOutline,
  LockOutline,
  PlaySquareOutline,
  PlusSquareOutline,//Upload file icon.
  ForwardOutline,  //TODO: ForwardOutline is temporary for mockup. We don't actually plan to use it.
  ExpandAltOutline, //TODO: ExpandAltOutline is temporary for mockup. We don't actually plan to use it.
  CloseOutline, //TODO: Temporary placeholder for table icons.
  BlockOutline, //TODO: Temporary placeholder for table icons.
  RedoOutline, //TODO: Temporary placeholder for table icons.
  UndoOutline, //TODO: Temporary placeholder for table icons.
  DownloadOutline, //TODO: Temporary placeholder for table icons.
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [
    { provide: NZ_ICONS, useValue: icons }
  ]
})
export class IconsProviderModule {
}
