import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {ParamConfigComponent} from "./param-config.component";
import {SharedModule} from "../../modules/shared.module";
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzModalModule} from "ng-zorro-antd/modal";
import { TabParamConfigComponent } from './tab-param-config/tab-param-config.component';
import {ColorPickerModule} from "ngx-color-picker";
import {TextFieldModule} from "@angular/cdk/text-field";
import {ScrollingModule} from "@angular/cdk/scrolling";
import { ParamConfigDataTableComponent } from 'src/app/shared/components/param-config-data-table/param-config-data-table.component';


const routes: Routes = [
  {
    path: '',
    component: ParamConfigComponent
  }
];


@NgModule({
  declarations: [
    TabParamConfigComponent,
    ParamConfigDataTableComponent
  ],
  exports: [
    TabParamConfigComponent
  ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
        NzPageHeaderModule,
        NzSpinModule,
        NzTableModule,
        NzModalModule,
        ColorPickerModule,
        TextFieldModule,
        ScrollingModule,
    ]
})
export class ParamConfigModule { }
