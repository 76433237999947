export const ENTITIES_CATEGORIES_DISPLAY_NAMES = {
  parcel: 'PARCEL',
  network: 'NETWORK',
  poi: 'POI',
  influence: 'INFLUENCE',
  building: 'BUILDING',
  guideline_elements: 'GUIDELINE ELEMENTS',
  default: 'DEFAULT'
};

export const WIDGET_OPTIONS = ['SLIDER', 'INPUT_NUMBER', 'INPUT_TEXT', 'CHECKBOX', 'MULTISELECT', 'DROPDOWN', 'LABEL'];
